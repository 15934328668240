.container {
  width: 100%;
  height: 100%;
}

.verticalHandleWrapper {
  position: absolute;
  height: 100%;
  transform: translateX(-50%);
}

.cornerHandleWrapper {
  position: absolute;
  transform: translate(-50%, -50%);
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: var(--color-gray-0);
  opacity: 0.4;
  z-index: 1;
}

.content {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 16px 24px;
  border: 1px solid var(--color-gray-1);
  border-radius: 16px;
  background-color: var(--color-gray-0);
  box-shadow: var(--shadow-bottom);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.form {
  display: grid;
  grid: 1fr auto / 1fr;
  gap: 16px;
}

.textareaWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.newLineHint {
  font-size: 12px;
  text-align: end;
  color: var(--color-gray-9);
}

.shortcut {
  font: var(--font-shortcut);
  font-size: 12px;
  color: var(--color-gray-10);
}

.textarea {
  height: 100px;
  padding: 16px;
  background-color: var(--color-gray-0);
  border: 1px solid var(--color-gray-9);
  border-radius: 8px;
  font-size: 16px;
  color: var(--color-gray-10);

  &::placeholder {
    color: var(--color-gray-9);
  }
}

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.alignmentButtons {
  display: flex;
  align-items: center;
}

.alignmentButton {
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    path {
      fill: var(--color-gray-10);
    }
  }

  &[data-state="on"] {
    background-color: var(--color-gray-1);

    path {
      fill: var(--color-gray-10);
    }
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.buttons {
  display: grid;
  grid: auto / auto auto;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
}

.button {
  min-width: 80px;
  padding: 8px 16px;
  background: none;
  border: 1px solid var(--color-gray-9);
  border-radius: 4px;
  font: var(--font-body);
  color: var(--color-gray-10);
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-1);
  }
}
