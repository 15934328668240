import { offsetPosition, Position } from "../position";
import { Box, boxOf } from "../box";
import { Offset } from "../offset";
import { Style } from "../style";

export interface ArrowInk {
  type: "arrow";
  kind: "drawing";
  style: Style;
  from: Position;
  to: Position;
}

export function createPreviewArrowInk(style: Style, pos: Position): ArrowInk {
  return { type: "arrow", kind: "drawing", style: style, from: pos, to: pos };
}

export function updatePreviewArrowInk(ink: ArrowInk, pos: Position): ArrowInk {
  return { ...ink, to: pos };
}

export function finalizePreviewArrowInk(ink: ArrowInk): ArrowInk {
  return ink;
}

export function boundArrowInk(ink: ArrowInk): Box {
  return boxOf(ink.from, ink.to);
}

export function moveArrowInk(ink: ArrowInk, offset: Offset): ArrowInk {
  return { ...ink, from: offsetPosition(ink.from, offset), to: offsetPosition(ink.to, offset) };
}

export function moveArrowInkFrom(ink: ArrowInk, offset: Offset): ArrowInk {
  return { ...ink, from: offsetPosition(ink.from, offset) };
}

export function moveArrowInkTo(ink: ArrowInk, offset: Offset): ArrowInk {
  return { ...ink, to: offsetPosition(ink.to, offset) };
}
