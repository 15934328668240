import { FC, ReactNode, useCallback, useEffect, useRef } from "react";
import styles from "./Viewport.module.scss";
import { Offset } from "../model/offset";

import { ViewportPosition } from "../model/position";

interface Props {
  pan: Offset;
  zoom: number;
  onPan: (offset: Offset) => void;
  onZoom: (origin: ViewportPosition, factor: number) => void;
  children?: ReactNode;
}

export const Viewport: FC<Props> = (props) => {
  const { onPan, onZoom } = props;
  const ref = useRef<HTMLDivElement>(null);

  // WheelEvent を preventDefault するには passive: false でイベントリスナを登録する必要があるが、
  // React の onWheel では passive: false にできないため useEffect でリスナを追加する。
  // https://github.com/facebook/react/issues/22794
  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    const div = ref.current;
    const handler = (e: WheelEvent) => {
      e.preventDefault();
      requestAnimationFrame(() => {
        if (e.metaKey || e.ctrlKey) {
          const rect = div.getBoundingClientRect();
          onZoom(
            {
              viewportX: e.pageX - (rect.x + window.scrollX),
              viewportY: e.pageY - (rect.y + window.scrollY),
            },
            -e.deltaY / (e.metaKey ? 200 : 50) + 1,
          );
        } else {
          onPan({
            deltaX: (e.deltaX / props.zoom) * 1.5,
            deltaY: (e.deltaY / props.zoom) * 1.5,
          });
        }
      });
    };
    div.addEventListener("wheel", handler, { passive: false });
    return () => {
      div.removeEventListener("wheel", handler);
    };
  }, [onZoom, onPan, props.zoom]);

  return (
    <div ref={ref} className={styles.container}>
      {props.children}
    </div>
  );
};
