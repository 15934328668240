import { IconProps } from "./props";
import { FC } from "react";

export const BlurToolIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <mask
        id="mask0_264_187"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_264_187)">
        <path
          d="M12.2463 18.8461C12.4207 18.8295 12.5642 18.7642 12.6771 18.6504C12.7899 18.5366 12.8463 18.3993 12.8463 18.2386C12.8463 18.0513 12.7848 17.9028 12.6618 17.7931C12.5388 17.6835 12.3817 17.637 12.1906 17.6536C11.5072 17.7036 10.763 17.5034 9.95782 17.0529C9.15266 16.6022 8.65007 15.8186 8.45007 14.7019C8.41674 14.5314 8.34524 14.3974 8.23557 14.2999C8.12607 14.2024 7.99566 14.1536 7.84432 14.1536C7.66232 14.1536 7.51282 14.222 7.39582 14.3586C7.27882 14.4951 7.23791 14.6704 7.27307 14.8844C7.54357 16.3371 8.19749 17.3756 9.23482 17.9999C10.272 18.6242 11.2758 18.9063 12.2463 18.8461ZM11.9983 21.3989C9.89249 21.3989 8.13316 20.6726 6.72032 19.2201C5.30749 17.7676 4.60107 15.9609 4.60107 13.7999C4.60107 12.2756 5.21982 10.5887 6.45732 8.73939C7.69482 6.89006 9.54241 4.89514 12.0001 2.75464C14.4577 4.89514 16.3053 6.89006 17.5428 8.73939C18.7803 10.5887 19.3991 12.2756 19.3991 13.7999C19.3991 15.9609 18.6921 17.7676 17.2781 19.2201C15.8641 20.6726 14.1042 21.3989 11.9983 21.3989ZM12.0001 19.9999C13.7334 19.9999 15.1667 19.4124 16.3001 18.2374C17.4334 17.0624 18.0001 15.5832 18.0001 13.7999C18.0001 12.5832 17.4959 11.2082 16.4876 9.67489C15.4792 8.14156 13.9834 6.46656 12.0001 4.64989C10.0167 6.46656 8.52091 8.14156 7.51257 9.67489C6.50424 11.2082 6.00007 12.5832 6.00007 13.7999C6.00007 15.5832 6.56674 17.0624 7.70007 18.2374C8.83341 19.4124 10.2667 19.9999 12.0001 19.9999Z"
          fill="#A3A3A3"
        />
      </g>
    </svg>
  );
};
