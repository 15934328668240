import styles from "./RectInkEditor.module.scss";
import { FC, useCallback, useRef } from "react";
import { InkEditorProps } from "./InkEditor";
import { CornerHandle } from "../../views/CornerHandle";
import { Offset } from "../../model/offset";
import {
  ImageInk,
  moveImageInkBottomLeftCorner,
  moveImageInkBottomRightCorner,
  moveImageInkTopLeftCorner,
  moveImageInkTopRightCorner,
} from "../../model/tool/image";

export const ImageInkEditor: FC<InkEditorProps<ImageInk>> = (props) => {
  const { onChange } = props;
  const draggingInkRef = useRef<ImageInk>();

  const handleDragStart = useCallback(() => {
    draggingInkRef.current = props.ink;
  }, [props.ink]);

  const handleTopLeftCornerDragMove = useCallback(
    (offset: Offset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveImageInkTopLeftCorner(draggingInkRef.current, offset));
    },
    [onChange],
  );

  const handleTopRightCornerDragMove = useCallback(
    (offset: Offset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveImageInkTopRightCorner(draggingInkRef.current, offset));
    },
    [onChange],
  );

  const handleBottomRightCornerDragMove = useCallback(
    (offset: Offset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveImageInkBottomRightCorner(draggingInkRef.current, offset));
    },
    [onChange],
  );

  const handleBottomLeftCornerDragMove = useCallback(
    (offset: Offset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveImageInkBottomLeftCorner(draggingInkRef.current, offset));
    },
    [onChange],
  );

  const handleDragEnd = useCallback(() => {
    draggingInkRef.current = undefined;
  }, []);

  return (
    <div className={styles.container} onDoubleClick={() => console.log("double click")}>
      <div style={{ top: 0, left: 0 }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nwse"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleTopLeftCornerDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: 0, left: "100%" }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nesw"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleTopRightCornerDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: "100%", left: "100%" }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nwse"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleBottomRightCornerDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: "100%", left: 0 }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nesw"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleBottomLeftCornerDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
    </div>
  );
};
