import { IconProps } from "./props";
import { FC } from "react";

export const XlSizeIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M17.3589 17.687C16.8576 17.687 16.6069 17.4198 16.6069 16.8856V6.80139C16.6069 6.26713 16.8469 6 17.3269 6C17.8176 6 18.0629 6.26713 18.0629 6.80139V16.2511H23.2949C23.8069 16.2511 24.0629 16.4904 24.0629 16.969C24.0629 17.4477 23.8069 17.687 23.2949 17.687H17.3589Z"
        fill="#ffffff"
      />
      <path
        d="M7.08238 17.687C6.70905 17.687 6.48505 17.5868 6.41038 17.3864C6.33571 17.1861 6.37305 16.9635 6.52238 16.7186L9.67438 11.5597L6.84238 6.96835C6.62905 6.64557 6.57571 6.40626 6.68238 6.25043C6.78905 6.08348 7.02371 6 7.38638 6C7.79171 6 8.09571 6.17809 8.29838 6.53426L10.4744 10.1739L12.6344 6.53426C12.837 6.17809 13.141 6 13.5464 6C14.261 6 14.4424 6.32278 14.0904 6.96835L11.2744 11.5597L14.4264 16.7186C14.597 16.9969 14.6344 17.2306 14.5384 17.4198C14.453 17.5979 14.2237 17.687 13.8504 17.687C13.445 17.687 13.141 17.5089 12.9384 17.1527L10.4744 12.9621L7.99438 17.1527C7.79171 17.5089 7.48771 17.687 7.08238 17.687Z"
        fill="#ffffff"
      />
    </svg>
  );
};
