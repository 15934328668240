import { FC, KeyboardEvent, MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import styles from "./TextInkEditor.module.scss";
import { moveTextInkLeftEdge, moveTextInkRightEdge, TextInk } from "../../model/tool/text";
import { InkEditorProps } from "./InkEditor";
import { VerticalHandle } from "../../views/VerticalHandle";
import { Offset } from "../../model/offset";
import { CornerHandle } from "../../views/CornerHandle";
import { TextInkEditDialog } from "../TextInkEditDialog";

export const TextInkEditor: FC<InkEditorProps<TextInk>> = (props) => {
  const { onChange, onCancelCreate } = props;
  const ref = useRef<HTMLDivElement>(null);
  const draggingInkRef = useRef<TextInk>();
  const [open, setOpen] = useState(false);

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      e.preventDefault();
      setOpen(true);
    }
  }, []);

  const handleDoubleClick = useCallback(() => {
    setOpen(true);
  }, [props.ink.text]);

  const handleVerticalEdgeDoubleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onChange({ ...props.ink, width: undefined });
    },
    [onChange, props.ink],
  );

  const handleDragStart = useCallback(() => {
    draggingInkRef.current = props.ink;
  }, [props.ink]);

  const handleRightEdgeDragMove = useCallback(
    (offset: Offset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveTextInkRightEdge(draggingInkRef.current, offset));
    },
    [onChange],
  );

  const handleLeftEdgeDragMove = useCallback(
    (offset: Offset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveTextInkLeftEdge(draggingInkRef.current, offset));
    },
    [onChange],
  );

  const handleDragEnd = useCallback(() => {
    draggingInkRef.current = undefined;
  }, []);

  const handleSubmit = useCallback(
    (ink: TextInk) => {
      if (ink.text === "") {
        if (props.ink.text === "") {
          // 最初から空文字だった場合はただの削除ではなく作成キャンセルの扱いにする。
          // 作成キャンセルによる削除は history に記録されない。
          onCancelCreate();
        } else {
          onChange(undefined);
        }
      } else {
        onChange(ink);
      }
      setOpen(false);
      setTimeout(() => {
        // Radix Dialog が閉じるときのフォーカスマネジメントと競合する？timeout しないとフォーカスできない
        ref.current?.focus();
      }, 0);
    },
    [onChange],
  );

  const handleCancel = useCallback(() => {
    if (props.ink.text === "") {
      onCancelCreate();
    }
    setOpen(false);
    setTimeout(() => {
      // Radix Dialog が閉じるときのフォーカスマネジメントと競合する？timeout しないとフォーカスできない
      ref.current?.focus();
    }, 0);
  }, [props.ink.text]);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div
      ref={ref}
      tabIndex={-1}
      className={styles.container}
      onKeyDown={handleKeyDown}
      onDoubleClick={handleDoubleClick}
    >
      <div
        style={{ top: 0, left: "100%" }}
        className={styles.verticalHandleWrapper}
        onDoubleClick={handleVerticalEdgeDoubleClick}
      >
        <VerticalHandle
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleRightEdgeDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div
        style={{ top: 0, left: 0 }}
        className={styles.verticalHandleWrapper}
        onDoubleClick={handleVerticalEdgeDoubleClick}
      >
        <VerticalHandle
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleLeftEdgeDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: 0, left: 0 }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nwse"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleLeftEdgeDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: 0, left: "100%" }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nesw"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleRightEdgeDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: "100%", left: "100%" }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nwse"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleRightEdgeDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: "100%", left: 0 }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nesw"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleLeftEdgeDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      {open && (
        <TextInkEditDialog
          initialValue={props.ink}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};
