import { IconProps } from "./props";
import { FC } from "react";

export const XxlSizeIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M22.2652 17.687C21.7638 17.687 21.5132 17.4198 21.5132 16.8856V6.80139C21.5132 6.26713 21.7532 6 22.2332 6C22.7238 6 22.9692 6.26713 22.9692 6.80139V16.2511H28.2012C28.7132 16.2511 28.9692 16.4904 28.9692 16.969C28.9692 17.4477 28.7132 17.687 28.2012 17.687H22.2652Z"
        fill="#ffffff"
      />
      <path
        d="M11.9886 17.687C11.6153 17.687 11.3913 17.5868 11.3166 17.3864C11.242 17.1861 11.2793 16.9635 11.4286 16.7186L14.5806 11.5597L11.7486 6.96835C11.5353 6.64557 11.482 6.40626 11.5886 6.25043C11.6953 6.08348 11.93 6 12.2926 6C12.698 6 13.002 6.17809 13.2046 6.53426L15.3806 10.1739L17.5406 6.53426C17.7433 6.17809 18.0473 6 18.4526 6C19.1673 6 19.3486 6.32278 18.9966 6.96835L16.1806 11.5597L19.3326 16.7186C19.5033 16.9969 19.5406 17.2306 19.4446 17.4198C19.3593 17.5979 19.13 17.687 18.7566 17.687C18.3513 17.687 18.0473 17.5089 17.8446 17.1527L15.3806 12.9621L12.9006 17.1527C12.698 17.5089 12.394 17.687 11.9886 17.687Z"
        fill="#ffffff"
      />
      <path
        d="M2.17613 17.687C1.8028 17.687 1.5788 17.5868 1.50413 17.3864C1.42946 17.1861 1.4668 16.9635 1.61613 16.7186L4.76813 11.5597L1.93613 6.96835C1.7228 6.64557 1.66946 6.40626 1.77613 6.25043C1.8828 6.08348 2.11746 6 2.48013 6C2.88546 6 3.18946 6.17809 3.39213 6.53426L5.56813 10.1739L7.72813 6.53426C7.9308 6.17809 8.2348 6 8.64013 6C9.3548 6 9.53613 6.32278 9.18413 6.96835L6.36813 11.5597L9.52013 16.7186C9.6908 16.9969 9.72813 17.2306 9.63213 17.4198C9.5468 17.5979 9.31746 17.687 8.94413 17.687C8.5388 17.687 8.2348 17.5089 8.03213 17.1527L5.56813 12.9621L3.08813 17.1527C2.88546 17.5089 2.58146 17.687 2.17613 17.687Z"
        fill="#ffffff"
      />
    </svg>
  );
};
