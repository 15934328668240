import { IconProps } from "./props";
import { FC } from "react";

export const AlignCenterIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <mask
        id="mask0_463_9745"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_463_9745)">
        <path d="M5 19V17H19V19H5ZM7 13V11H17V13H7ZM3 7V5H21V7H3Z" fill="#A3A3A3" />
      </g>
    </svg>
  );
};
