import { Item } from "../model/item";
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { PixiCanvas, PixiCanvasRef } from "./PixiCanvas";
import { useToaster } from "../helpers/toaster";
import {
  boundInk,
  changeInkColor,
  changeInkSize,
  createPreviewInk,
  finalizePreviewInk,
  Ink,
  moveInk,
  Tool,
  updatePreviewInk,
} from "../model/tool";
import { Color, Size, Style } from "../model/style";
import {
  fromViewportPosition,
  offsetPosition,
  Position,
  ViewportPosition,
} from "../model/position";
import { createPreviewImageInk, finalizePreviewImageInk } from "../model/tool/image";
import { addOffset, invertOffset, Offset } from "../model/offset";
import styles from "./App.module.scss";
import { Viewport } from "./Viewport";
import { Editor } from "./Editor";
import { Logo } from "../views/Logo";
import { ToolBar } from "./ToolBar";
import { MenuBar, MenuBarMenuItem } from "./MenuBar";
import { assertNever } from "../lib/assert-never";
import { loadPng, Png } from "../model/png";
import { Welcome } from "./Welcome";
import { useTexts } from "../helpers/texts";
import { trackEvent } from "../analytics";
import { ItemEditor } from "./ItemEditor";
import { ItemContextMenuItem } from "./ItemContextMenu";
import { TextInkEditDialog } from "./TextInkEditDialog";
import { finalizePreviewTextInk, TextInk } from "../model/tool/text";
import { fromViewportBox, isBoxTouching, normalizeBox, ViewportBox } from "../model/box";

const textMap = {
  "reset zoom to 100%": {
    en: "Reset zoom to 100%",
    ja: "ズームを 100% にリセットしました",
  },
  "pasted from clipboard": {
    en: "Pasted from clipboard",
    ja: "クリップボードから貼り付けました",
  },
  "copied to clipboard": {
    en: "Copied to clipboard",
    ja: "クリップボードにコピーしました",
  },
  "file exported": {
    en: "File exported",
    ja: "ファイルにエクスポートしました",
  },
};

type State = {
  pan: Offset;
  zoom: number;
  tool: Tool | undefined;
  style: Style;
  mainItem: Item | undefined;
  items: Item[];
  draftItem: Item | undefined;
  draftTextItem: Item<TextInk> | undefined;
  activeItemIds: string[];
  history: History<Pick<State, "items">>;
};

type HistoryStep<T> = {
  ts: number;
  state: T;
};

type History<T> = {
  past: HistoryStep<T>[];
  future: HistoryStep<T>[];
};

type Action =
  | { type: "pan"; offset: Offset }
  | { type: "zoom"; origin: ViewportPosition; factor: number }
  | { type: "set_zoom"; origin: ViewportPosition; zoom: number }
  | { type: "reset_zoom"; origin: ViewportPosition }
  | { type: "reset_viewport" }
  | { type: "add_image_item"; pos: ViewportPosition; png: Png }
  | { type: "move_items"; offset: Offset }
  | { type: "draw"; pos: ViewportPosition }
  | { type: "finish_draw" }
  | { type: "finish_draw_text_ink"; ink: TextInk | undefined }
  | { type: "update_item"; item: Item }
  | { type: "select_item"; itemId: string; multiple: boolean }
  | { type: "select_all_items" }
  | { type: "select_item_if_not"; itemId: string; multiple: boolean }
  | { type: "select_area"; area: ViewportBox; multiple: boolean }
  | { type: "delete_item"; itemId: string }
  | { type: "delete_items" }
  | { type: "cancel_create_item"; itemId: string }
  | { type: "bring_to_front" }
  | { type: "bring_forward" }
  | { type: "send_backward" }
  | { type: "send_to_back" }
  | { type: "change_color"; color: Color }
  | { type: "change_size"; size: Size }
  | { type: "set_tool"; tool: Tool | undefined }
  | { type: "escape" }
  | { type: "undo" }
  | { type: "redo" };

function reducer(state: State, action: Action): State {
  if (action.type === "pan") {
    return { ...state, pan: addOffset(state.pan, action.offset) };
  }
  if (action.type === "zoom") {
    const pos = offsetPosition(
      fromViewportPosition(action.origin, state.pan, state.zoom),
      invertOffset(state.pan),
    );
    const newScale = minmax(0.02, 256, state.zoom * action.factor);
    const ratio = newScale / state.zoom;
    return {
      ...state,
      zoom: newScale,
      pan: {
        deltaX: state.pan.deltaX + (pos.x - pos.x / ratio),
        deltaY: state.pan.deltaY + (pos.y - pos.y / ratio),
      },
    };
  }
  if (action.type === "reset_zoom") {
    const pos = offsetPosition(
      fromViewportPosition(action.origin, state.pan, state.zoom),
      invertOffset(state.pan),
    );
    const newScale = 1;
    const ratio = newScale / state.zoom;
    return {
      ...state,
      zoom: 1,
      pan: {
        deltaX: state.pan.deltaX + (pos.x - pos.x / ratio),
        deltaY: state.pan.deltaY + (pos.y - pos.y / ratio),
      },
    };
  }
  if (action.type === "set_zoom") {
    const pos = offsetPosition(
      fromViewportPosition(action.origin, state.pan, state.zoom),
      invertOffset(state.pan),
    );
    const newScale = minmax(0.02, 256, action.zoom);
    const ratio = newScale / state.zoom;
    return {
      ...state,
      zoom: newScale,
      pan: {
        deltaX: state.pan.deltaX + (pos.x - pos.x / ratio),
        deltaY: state.pan.deltaY + (pos.y - pos.y / ratio),
      },
    };
  }
  if (action.type === "reset_viewport") {
    return {
      ...state,
      pan: { deltaX: 0, deltaY: 0 },
      zoom: 1,
    };
  }
  if (action.type === "add_image_item") {
    const center = fromViewportPosition(action.pos, state.pan, state.zoom);
    const ink = finalizePreviewImageInk(createPreviewImageInk(center, action.png));
    const item: Item = { id: crypto.randomUUID(), ink: ink };
    if (state.mainItem === undefined) {
      return { ...state, mainItem: item };
    } else {
      return {
        ...state,
        items: [...state.items, item],
        activeItemIds: [item.id],
        history: addHistoryStep(state.history, {
          items: state.items,
        }),
      };
    }
  }
  if (action.type === "move_items") {
    return {
      ...state,
      items: state.items.map((item) =>
        state.activeItemIds.includes(item.id)
          ? { ...item, ink: moveInk(item.ink, action.offset) }
          : item,
      ),
      history: addHistoryStep(state.history, {
        items: state.items,
      }),
    };
  }
  if (action.type === "draw") {
    const pos = fromViewportPosition(action.pos, state.pan, state.zoom);
    if (state.draftItem === undefined) {
      if (state.tool === undefined) {
        return state;
      }
      return {
        ...state,
        draftItem: {
          id: crypto.randomUUID(),
          ink: createPreviewInk(state.tool, state.style, pos),
        },
      };
    }
    return {
      ...state,
      draftItem: {
        ...state.draftItem,
        ink: updatePreviewInk(state.draftItem.ink, pos),
      },
    };
  }
  if (action.type === "finish_draw") {
    if (state.draftItem === undefined) {
      return state;
    }
    if (state.draftItem.ink.type === "text") {
      return {
        ...state,
        draftItem: undefined,
        draftTextItem: { ...state.draftItem, ink: state.draftItem.ink },
      };
    }
    const ink = finalizePreviewInk(state.draftItem.ink);
    if (ink === undefined) {
      return {
        ...state,
        tool: undefined,
        draftItem: undefined,
        activeItemIds: [],
      };
    }
    return {
      ...state,
      tool: state.tool === "pen" ? state.tool : undefined,
      draftItem: undefined,
      items: [...state.items, { ...state.draftItem, ink: ink }],
      activeItemIds: [state.draftItem.id],
      history: addHistoryStep(state.history, {
        items: state.items,
      }),
    };
  }
  if (action.type === "finish_draw_text_ink") {
    if (state.draftTextItem === undefined) {
      return state;
    }
    const ink = action.ink === undefined ? undefined : finalizePreviewTextInk(action.ink);
    if (ink === undefined) {
      return {
        ...state,
        tool: undefined,
        draftTextItem: undefined,
      };
    }
    return {
      ...state,
      tool: undefined,
      items: [...state.items, { ...state.draftTextItem, ink: ink }],
      draftTextItem: undefined,
      activeItemIds: [state.draftTextItem.id],
      history: addHistoryStep(state.history, {
        items: state.items,
      }),
    };
  }
  if (action.type === "update_item") {
    return {
      ...state,
      items: state.items.map((it) => (it.id === action.item.id ? action.item : it)),
      history: addHistoryStep(state.history, {
        items: state.items,
      }),
    };
  }
  if (action.type === "select_item") {
    const item = state.items.find((it) => it.id === action.itemId);
    return {
      ...state,
      activeItemIds: action.multiple ? [...state.activeItemIds, action.itemId] : [action.itemId],
      // 選択したアイテムのスタイルを現在のスタイルにする
      style: item !== undefined ? { ...state.style, ...extractStyle(item.ink) } : state.style,
    };
  }
  if (action.type === "select_all_items") {
    return {
      ...state,
      activeItemIds: state.items.map((item) => item.id),
    };
  }
  if (action.type === "select_item_if_not") {
    const item = state.items.find((it) => it.id === action.itemId);
    return {
      ...state,
      activeItemIds: state.activeItemIds.includes(action.itemId)
        ? state.activeItemIds
        : action.multiple
          ? [...state.activeItemIds, action.itemId]
          : [action.itemId],
      // 選択したアイテムのスタイルを現在のスタイルにする
      style: item !== undefined ? { ...state.style, ...extractStyle(item.ink) } : state.style,
    };
  }
  if (action.type === "select_area") {
    const box = fromViewportBox(action.area, state.pan, state.zoom);
    const touchingItemIds = state.items
      .filter((item) => {
        const boundingBox = normalizeBox(boundInk(item.ink));
        return isBoxTouching(boundingBox, box);
      })
      .map((it) => it.id);
    const firstItem = state.items.find((it) => it.id === touchingItemIds[0]);
    return {
      ...state,
      activeItemIds: action.multiple
        ? [...state.activeItemIds, ...touchingItemIds]
        : touchingItemIds,
      // 選択したアイテムのスタイルを現在のスタイルにする
      style:
        firstItem !== undefined ? { ...state.style, ...extractStyle(firstItem.ink) } : state.style,
    };
  }
  if (action.type === "delete_item") {
    return {
      ...state,
      items: state.items.filter((it) => it.id !== action.itemId),
      history: addHistoryStep(state.history, {
        items: state.items,
      }),
    };
  }
  if (action.type === "delete_items") {
    return {
      ...state,
      items: state.items.filter((item) => !state.activeItemIds.includes(item.id)),
      history: addHistoryStep(state.history, {
        items: state.items,
      }),
    };
  }
  if (action.type === "cancel_create_item") {
    return {
      ...state,
      items: state.items.filter((it) => it.id !== action.itemId),
    };
  }
  if (action.type === "bring_to_front") {
    return {
      ...state,
      items: bringForward(state.items, state.activeItemIds, true),
      history: addHistoryStep(state.history, {
        items: state.items,
      }),
    };
  }
  if (action.type === "bring_forward") {
    return {
      ...state,
      items: bringForward(state.items, state.activeItemIds, false),
      history: addHistoryStep(state.history, {
        items: state.items,
      }),
    };
  }
  if (action.type === "send_backward") {
    return {
      ...state,
      items: sendBackward(state.items, state.activeItemIds, false),
      history: addHistoryStep(state.history, {
        items: state.items,
      }),
    };
  }
  if (action.type === "send_to_back") {
    return {
      ...state,
      items: sendBackward(state.items, state.activeItemIds, true),
      history: addHistoryStep(state.history, {
        items: state.items,
      }),
    };
  }
  if (action.type === "change_color") {
    let modified = false;
    const newItems: Item[] = [];
    for (const item of state.items) {
      if (state.activeItemIds.includes(item.id)) {
        newItems.push({ ...item, ink: changeInkColor(item.ink, action.color) });
        modified = true;
      } else {
        newItems.push(item);
      }
    }
    return {
      ...state,
      style: { ...state.style, color: action.color },
      items: modified ? newItems : state.items,
      history: modified
        ? addHistoryStep(state.history, {
            items: state.items,
          })
        : state.history,
    };
  }
  if (action.type === "change_size") {
    let modified = false;
    const newItems: Item[] = [];
    for (const item of state.items) {
      if (state.activeItemIds.includes(item.id)) {
        newItems.push({ ...item, ink: changeInkSize(item.ink, action.size) });
        modified = true;
      } else {
        newItems.push(item);
      }
    }
    return {
      ...state,
      style: { ...state.style, size: action.size },
      items: modified ? newItems : state.items,
      history: modified
        ? addHistoryStep(state.history, {
            items: state.items,
          })
        : state.history,
    };
  }
  if (action.type === "set_tool") {
    return {
      ...state,
      tool: action.tool,
    };
  }
  if (action.type === "escape") {
    return {
      ...state,
      tool: undefined,
      activeItemIds: [],
    };
  }
  if (action.type === "undo") {
    const past = [...state.history.past];
    const last = past.pop();
    if (last === undefined) {
      return state;
    }
    console.log("undo:", last.state);
    return {
      ...state,
      ...last.state,
      history: {
        past: past,
        future: [...state.history.future, { ts: Date.now(), state: { items: state.items } }],
      },
    };
  }
  if (action.type === "redo") {
    const future = [...state.history.future];
    const recent = future.pop();
    if (recent === undefined) {
      return state;
    }
    console.log("redo:", recent.state);
    return {
      ...state,
      ...recent.state,
      history: {
        past: [...state.history.past, { ts: Date.now(), state: { items: state.items } }],
        future: future,
      },
    };
  }
  assertNever(action);
}

function addHistoryStep<T>(history: History<T>, state: T): History<T> {
  const step = { ts: Date.now(), state: state };
  if (history.past.length === 0) {
    console.log("history added:", step);
    return { past: [step], future: [] };
  }
  const last = history.past[history.past.length - 1];
  if (step.ts - last.ts <= 100) {
    return { past: [...history.past.slice(0, -1), { ts: step.ts, state: last.state }], future: [] };
  }
  console.log("history added:", step);
  return { past: [...history.past, step], future: [] };
}

function extractStyle(ink: Ink): Partial<Style> {
  if (ink.kind === "drawing") {
    return ink.style;
  }
  if (ink.kind === "filter") {
    return { size: ink.size };
  }
  if (ink.kind === "image") {
    return {};
  }
  assertNever(ink);
}

function minmax(min: number, max: number, x: number): number {
  return Math.max(min, Math.min(max, x));
}

export const App = () => {
  const texts = useTexts(textMap);
  const canvasRef = useRef<HTMLDivElement>(null);
  const pixiCanvasRef = useRef<PixiCanvasRef>(null);
  const { show } = useToaster();
  const [cursorPos, setCursorPos] = useState<Position>({ x: 0, y: 0 });
  const [state, dispatch] = useReducer(reducer, {
    pan: { deltaX: 0, deltaY: 0 },
    zoom: 1,
    tool: undefined,
    style: { color: "red", size: "m" },
    mainItem: undefined,
    items: [],
    draftItem: undefined,
    draftTextItem: undefined,
    activeItemIds: [],
    history: { past: [], future: [] },
  });

  const allItems = useMemo(() => {
    return concatItems(state.mainItem, ...state.items, state.draftItem);
  }, [state.mainItem, state.items, state.draftItem]);

  const addImageItem = useCallback(async (png: Png) => {
    const rect = canvasRef.current?.getBoundingClientRect();
    if (rect === undefined) {
      return;
    }
    const center = { viewportX: rect.width / 2, viewportY: rect.height / 2 };
    dispatch({ type: "add_image_item", pos: center, png: png });
  }, []);

  const exportToClipboard = useCallback(async () => {
    if (pixiCanvasRef.current === null) {
      return;
    }
    const item = new ClipboardItem({
      "image/png": await pixiCanvasRef.current.exportPng(),
    });
    await navigator.clipboard.write([item]);
    show(texts["copied to clipboard"]);
  }, [show, texts]);

  const exportToFile = useCallback(async () => {
    if (pixiCanvasRef.current === null) {
      return;
    }
    const png = await pixiCanvasRef.current.exportPng();
    const url = URL.createObjectURL(png);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = "download";
    a.click();
    URL.revokeObjectURL(url);
    show(texts["file exported"]);
  }, [show, texts]);

  const zoomIn = useCallback(() => {
    const rect = canvasRef.current?.getBoundingClientRect();
    if (rect === undefined) {
      return;
    }
    const origin = {
      viewportX: rect.width / 2,
      viewportY: rect.height / 2,
    };
    dispatch({ type: "zoom", origin: origin, factor: 1.25 });
  }, []);

  const zoomOut = useCallback(() => {
    const rect = canvasRef.current?.getBoundingClientRect();
    if (rect === undefined) {
      return;
    }
    const origin = {
      viewportX: rect.width / 2,
      viewportY: rect.height / 2,
    };
    dispatch({ type: "zoom", origin: origin, factor: 1 / 1.25 });
  }, []);

  const zoomTo100 = useCallback(() => {
    const rect = canvasRef.current?.getBoundingClientRect();
    if (rect === undefined) {
      return;
    }
    const origin = {
      viewportX: rect.width / 2,
      viewportY: rect.height / 2,
    };
    dispatch({ type: "reset_zoom", origin });
    show(texts["reset zoom to 100%"]);
  }, [show, texts]);

  const moveUp = useCallback((shift: boolean) => {
    dispatch({ type: "move_items", offset: { deltaX: 0, deltaY: shift ? -10 : -1 } });
  }, []);

  const moveRight = useCallback((shift: boolean) => {
    dispatch({ type: "move_items", offset: { deltaX: shift ? 10 : 1, deltaY: 0 } });
  }, []);

  const moveBottom = useCallback((shift: boolean) => {
    dispatch({ type: "move_items", offset: { deltaX: 0, deltaY: shift ? 10 : 1 } });
  }, []);

  const moveLeft = useCallback((shift: boolean) => {
    dispatch({ type: "move_items", offset: { deltaX: shift ? -10 : -1, deltaY: 0 } });
  }, []);

  const handleItemChange = useCallback((item: Item) => {
    dispatch({ type: "update_item", item: item });
  }, []);

  const handleItemDelete = useCallback((itemId: string) => {
    dispatch({ type: "delete_item", itemId: itemId });
  }, []);

  const handleItemCancelCreate = useCallback((itemId: string) => {
    dispatch({ type: "cancel_create_item", itemId: itemId });
  }, []);

  const handleItemDrag = useCallback((itemId: string, offset: Offset, multiple: boolean) => {
    dispatch({ type: "select_item_if_not", itemId: itemId, multiple: multiple });
    dispatch({ type: "move_items", offset: offset });
  }, []);

  const handleDraw = useCallback((pos: ViewportPosition) => {
    dispatch({ type: "draw", pos: pos });
  }, []);

  const handleDrawEnd = useCallback(() => {
    dispatch({ type: "finish_draw" });
  }, []);

  const handleSelectArea = useCallback((area: ViewportBox, multiple: boolean) => {
    dispatch({ type: "select_area", area: area, multiple: multiple });
  }, []);

  const handleItemClick = useCallback((itemId: string, shift: boolean) => {
    dispatch({ type: "select_item", itemId: itemId, multiple: shift });
  }, []);

  const handleItemContextMenuOpenChange = useCallback((itemId: string, open: boolean) => {
    if (open) {
      dispatch({ type: "select_item_if_not", itemId: itemId, multiple: false });
    }
  }, []);

  const handleItemContextMenuItemSelect = useCallback((menuItem: ItemContextMenuItem) => {
    switch (menuItem) {
      case "delete":
        dispatch({ type: "delete_items" });
        break;
      case "bring_to_front":
        dispatch({ type: "bring_to_front" });
        break;
      case "bring_forward":
        dispatch({ type: "bring_forward" });
        break;
      case "send_backward":
        dispatch({ type: "send_backward" });
        break;
      case "send_to_back":
        dispatch({ type: "send_to_back" });
        break;
      default:
        assertNever(menuItem);
    }
  }, []);

  const handleDraftTextInkSubmit = useCallback((ink: TextInk) => {
    dispatch({ type: "finish_draw_text_ink", ink: ink });
  }, []);

  const handleDraftTextInkCancel = useCallback(() => {
    dispatch({ type: "finish_draw_text_ink", ink: undefined });
  }, []);

  const handlePan = useCallback((offset: Offset) => {
    dispatch({ type: "pan", offset });
  }, []);

  const handleZoom = useCallback((origin: ViewportPosition, factor: number) => {
    dispatch({ type: "zoom", origin, factor });
  }, []);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    requestAnimationFrame(() => {
      setCursorPos({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    });
  }, []);

  const handleColorChange = useCallback((color: Color) => {
    dispatch({ type: "change_color", color: color });
  }, []);

  const handleSizeChange = useCallback((size: Size) => {
    dispatch({ type: "change_size", size: size });
  }, []);

  const handleWelcomeImageSelect = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file !== undefined) {
        const png = await loadPng(file);
        if (png !== undefined) {
          await addImageItem(png);
        }
      }
      if (state.mainItem === undefined) {
        await trackEvent({ name: "start", by: "select" });
      }
    },
    [addImageItem, state.mainItem],
  );

  const handleUndoClick = useCallback(() => {
    dispatch({ type: "undo" });
  }, []);

  const handleRedoClick = useCallback(() => {
    dispatch({ type: "redo" });
  }, []);

  const handleMenuBarMenuItemSelect = useCallback(
    async (item: MenuBarMenuItem) => {
      switch (item) {
        case "export_to_clipboard":
          await exportToClipboard();
          await trackEvent({ name: "export", to: "clipboard", via: "menu" });
          break;
        case "export_to_file":
          await exportToFile();
          await trackEvent({ name: "export", to: "file", via: "menu" });
          break;
        case "zoom_in":
          zoomIn();
          break;
        case "zoom_out":
          zoomOut();
          break;
        case "zoom_to_100":
          zoomTo100();
          break;
        case "select_all":
          dispatch({ type: "select_all_items" });
          break;
      }
    },
    [exportToClipboard, exportToFile, zoomIn, zoomOut, zoomTo100],
  );

  useEffect(() => {
    if (state.mainItem === undefined) {
      return;
    }
    const handler = async (e: KeyboardEvent) => {
      if (e.key === "r") {
        dispatch({ type: "set_tool", tool: "rect" });
      }
      if (e.key === "t") {
        dispatch({ type: "set_tool", tool: "text" });
      }
      if (e.key === "p" || e.key === "P") {
        dispatch({ type: "set_tool", tool: "pen" });
      }
      if ((e.key === "a" && !e.metaKey) || e.key === "L") {
        dispatch({ type: "set_tool", tool: "arrow" });
      }
      if (e.key === "n") {
        dispatch({ type: "set_tool", tool: "number" });
      }
      if (e.key === "b") {
        dispatch({ type: "set_tool", tool: "blur" });
      }
      if (e.key === "x") {
        dispatch({ type: "set_tool", tool: "pixelate" });
      }
      if (e.key === "d") {
        dispatch({ type: "set_tool", tool: "redact" });
      }
      if (e.key === "Escape") {
        dispatch({ type: "escape" });
      }
      if (e.key === "Backspace" || e.key === "Delete") {
        e.stopPropagation();
        dispatch({ type: "delete_items" });
      }
      if (e.metaKey && e.key === "a") {
        e.preventDefault();
        dispatch({ type: "select_all_items" });
      }
      if (e.key === "[") {
        e.preventDefault();
        if (e.metaKey) {
          dispatch({ type: "send_backward" });
        } else {
          dispatch({ type: "send_to_back" });
        }
      }
      if (e.key === "]") {
        e.preventDefault();
        if (e.metaKey) {
          dispatch({ type: "bring_forward" });
        } else {
          dispatch({ type: "bring_to_front" });
        }
      }
      if (e.metaKey && (e.key === ";" || e.key === "+")) {
        e.preventDefault();
        zoomIn();
      }
      if (e.metaKey && e.key === "-") {
        e.preventDefault();
        zoomOut();
      }
      if (e.metaKey && e.key === "0") {
        zoomTo100();
      }
      if (e.key === "ArrowUp") {
        moveUp(e.shiftKey);
      }
      if (e.key === "ArrowRight") {
        moveRight(e.shiftKey);
      }
      if (e.key === "ArrowDown") {
        moveBottom(e.shiftKey);
      }
      if (e.key === "ArrowLeft") {
        moveLeft(e.shiftKey);
      }
      if (e.metaKey && e.key === "z") {
        if (e.shiftKey) {
          dispatch({ type: "redo" });
        } else {
          dispatch({ type: "undo" });
        }
      }
      if (e.metaKey && e.shiftKey && e.key === "c") {
        e.preventDefault();
        await exportToClipboard();
        await trackEvent({ name: "export", to: "clipboard", via: "shortcut" });
      }
    };
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [
    state.mainItem,
    state.activeItemIds,
    exportToClipboard,
    zoomIn,
    zoomOut,
    zoomTo100,
    moveUp,
    moveRight,
    moveBottom,
    moveLeft,
  ]);

  useEffect(() => {
    const handler = async (e: ClipboardEvent) => {
      console.log("paste", e);
      const file = e.clipboardData?.files[0];
      if (file !== null && file !== undefined) {
        const png = await loadPng(file);
        if (png !== undefined) {
          await addImageItem(png);
          show(texts["pasted from clipboard"]);
          if (state.mainItem === undefined) {
            await trackEvent({ name: "start", by: "paste" });
          }
        }
      }
    };
    document.addEventListener("paste", handler);
    return () => {
      document.removeEventListener("paste", handler);
    };
  }, [addImageItem, show, texts, state.mainItem]);

  return (
    <div>
      <div ref={canvasRef} className={styles.canvas} onMouseMove={handleMouseMove}>
        {state.mainItem !== undefined && (
          <>
            <PixiCanvas ref={pixiCanvasRef} items={allItems} pan={state.pan} zoom={state.zoom} />
            <Viewport pan={state.pan} zoom={state.zoom} onPan={handlePan} onZoom={handleZoom}>
              <Editor
                tool={state.tool}
                activeItemIds={state.activeItemIds}
                onDraw={handleDraw}
                onDrawEnd={handleDrawEnd}
                onSelectArea={handleSelectArea}
              />
              {state.tool === undefined &&
                state.items.map((item) => (
                  <ItemEditor
                    key={item.id}
                    item={item}
                    pan={state.pan}
                    zoom={state.zoom}
                    isActive={state.activeItemIds.includes(item.id)}
                    onClick={(shift) => handleItemClick(item.id, shift)}
                    onDrag={(offset, multiple) => handleItemDrag(item.id, offset, multiple)}
                    onChange={handleItemChange}
                    onDelete={() => handleItemDelete(item.id)}
                    onCancelCreate={() => handleItemCancelCreate(item.id)}
                    onContextMenuOpenChange={(open) =>
                      handleItemContextMenuOpenChange(item.id, open)
                    }
                    onContextMenuItemSelect={handleItemContextMenuItemSelect}
                  />
                ))}
              {state.draftTextItem !== undefined && (
                <TextInkEditDialog
                  initialValue={state.draftTextItem.ink}
                  onSubmit={handleDraftTextInkSubmit}
                  onCancel={handleDraftTextInkCancel}
                />
              )}
            </Viewport>
          </>
        )}
      </div>
      {state.mainItem === undefined && (
        <div className={styles.welcomeWrapper}>
          <Welcome onImageSelect={handleWelcomeImageSelect} />
        </div>
      )}
      <div className={styles.logoWrapper}>
        <Logo />
      </div>
      <div className={styles.toolBarWrapper}>
        <ToolBar
          tool={state.tool}
          style={state.style}
          disabled={state.mainItem === undefined}
          onColorChange={handleColorChange}
          onSizeChange={handleSizeChange}
          onToolChange={(tool) => dispatch({ type: "set_tool", tool: tool })}
        />
      </div>
      <div className={styles.menuBarWrapper}>
        <MenuBar
          canUndo={state.history.past.length > 0}
          canRedo={state.history.future.length > 0}
          disabled={state.mainItem === undefined}
          onUndoClick={handleUndoClick}
          onRedoClick={handleRedoClick}
          onMenuItemSelect={handleMenuBarMenuItemSelect}
        />
      </div>
      <div className={styles.infoWrapper}>
        <p className={styles.info}>
          pan: [{state.pan.deltaX.toFixed(2)}, {state.pan.deltaY.toFixed(2)}]
        </p>
        <p className={styles.info}>zoom: {state.zoom.toFixed(2)}</p>
        <p className={styles.info}>
          cursor: [{cursorPos.x.toFixed(2)}, {cursorPos.y.toFixed(2)}]
        </p>
      </div>
    </div>
  );
};

function concatItems(...items: (Item | undefined)[]): Item[] {
  return items.filter((it) => it !== undefined) as Item[];
}

function bringForward(items: Item[], targetItemIds: string[], toFront: boolean): Item[] {
  const temp: Item[] = [];
  const result: Item[] = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (targetItemIds.includes(item.id)) {
      temp.push(item);
    } else {
      result.push(item);
      if (!toFront) {
        result.push(...temp);
        temp.length = 0;
      }
    }
  }
  result.push(...temp);
  return result;
}

function sendBackward(items: Item[], targetItemIds: string[], toBack: boolean): Item[] {
  const temp: Item[] = [];
  const result: Item[] = [];
  for (let i = items.length - 1; i >= 0; i--) {
    const item = items[i];
    if (targetItemIds.includes(item.id)) {
      temp.push(item);
    } else {
      result.push(item);
      if (!toBack) {
        result.push(...temp);
        temp.length = 0;
      }
    }
  }
  result.push(...temp);
  result.reverse();
  return result;
}
