import { FC, useCallback } from "react";
import styles from "./VerticalHandle.module.scss";
import { Offset } from "../model/offset";
import { useDrag } from "../lib/drag";

interface Props {
  zoom: number;
  onDragStart?: () => void;
  onDragMove?: (offset: Offset) => void;
  onDragEnd?: () => void;
}

export const VerticalHandle: FC<Props> = (props) => {
  const { onDragMove } = props;

  const handleDragMove = useCallback(
    (offset: Offset) => {
      onDragMove?.({
        deltaX: offset.deltaX / props.zoom,
        deltaY: 0,
      });
    },
    [onDragMove, props.zoom],
  );

  const handleMouseDown = useDrag({
    onDragStart: props.onDragStart,
    onDragMove: handleDragMove,
    onDragEnd: props.onDragEnd,
  });

  return <div className={styles.container} onMouseDown={handleMouseDown} />;
};
