import { IconProps } from "./props";
import { FC } from "react";

export const LSizeIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M12.4527 17.687C11.9514 17.687 11.7007 17.4198 11.7007 16.8856V6.80139C11.7007 6.26713 11.9407 6 12.4207 6C12.9113 6 13.1567 6.26713 13.1567 6.80139V16.2511H18.3887C18.9007 16.2511 19.1567 16.4904 19.1567 16.969C19.1567 17.4477 18.9007 17.687 18.3887 17.687H12.4527Z"
        fill="#ffffff"
      />
    </svg>
  );
};
