.container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.historyButtons {
  display: grid;
  grid: auto / auto auto;
  background-color: var(--color-gray-0);
  // outline の形と box-shadow の形をどちらも角丸にするために .historyButtons と .undo / .redo の
  // どちらにも border-radius の指定が必要
  border-radius: 999px;
  box-shadow: var(--shadow-bottom);
}

.undo {
  padding: 8px 8px 8px 16px;
  border-radius: 16px 0 0 16px;
}

.redo {
  padding: 8px 16px 8px 8px;
  border-radius: 0 16px 16px 0;
}

.menuWrapper {
  background-color: var(--color-gray-0);
  border-radius: 999px;
  box-shadow: var(--shadow-bottom);
}

.menu {
  padding: 8px;
  border-radius: 999px;
}

.button {
  display: grid;
  place-items: center;
  background: none;
  border: none;
  cursor: pointer;

  &:hover:not(&:disabled) {
    svg {
      path,
      rect {
        fill: var(--color-gray-10);
      }
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
