import { offsetPosition, Position } from "../position";
import { Box } from "../box";
import { Offset } from "../offset";
import { applySize, Style } from "../style";

export interface NumberInk {
  type: "number";
  kind: "drawing";
  style: Style;
  pos: Position;
  ts: number;
}

export const numberInkSize = 20;

export function createPreviewNumberInk(style: Style, pos: Position): NumberInk {
  return { type: "number", kind: "drawing", style: style, pos, ts: Date.now() };
}

export function updatePreviewNumberInk(ink: NumberInk, pos: Position): NumberInk {
  return { ...ink, pos };
}

export function finalizePreviewNumberInk(ink: NumberInk): NumberInk {
  return ink;
}

export function boundNumberInk(ink: NumberInk): Box {
  const size = applySize(numberInkSize, ink.style.size) * 1.6;
  return { x: ink.pos.x - size / 2, y: ink.pos.y - size / 2, width: size, height: size };
}

export function moveNumberInk(ink: NumberInk, offset: Offset): NumberInk {
  return { ...ink, pos: offsetPosition(ink.pos, offset) };
}
