import { offsetPosition, Position } from "../position";
import { Box } from "../box";
import { Offset } from "../offset";
import { Style } from "../style";

export interface PenInk {
  type: "pen";
  kind: "drawing";
  style: Style;
  points: Position[];
}

export function createPreviewPenInk(style: Style, pos: Position): PenInk {
  return { type: "pen", kind: "drawing", style: style, points: [pos] };
}

export function updatePreviewPenInk(ink: PenInk, pos: Position): PenInk {
  return { ...ink, points: [...ink.points, pos] };
}

export function finalizePreviewPenInk(ink: PenInk): PenInk {
  return ink;
  // return { ...ink, points: douglasPeucker(ink.points) };
}

function douglasPeucker(points: Position[], epsilon: number = 0): Position[] {
  if (points.length === 2) {
    return points;
  }
  let dmax = 0;
  let index = 0;
  for (let i = 1; i < points.length - 1; i++) {
    const d = perpendicularDistance(points[0], points[points.length - 1], points[i]);
    if (d > dmax) {
      index = i;
      dmax = d;
    }
  }
  if (dmax <= epsilon) {
    return [points[0], points[points.length - 1]];
  }
  return [
    ...douglasPeucker(points.slice(0, index), epsilon),
    ...douglasPeucker(points.slice(index + 1), epsilon).slice(1),
  ];
}

function perpendicularDistance(start: Position, end: Position, point: Position): number {
  const dx = end.x - start.x;
  const dy = end.y - start.y;
  return (
    Math.abs(dy * point.x - dx * point.y + end.x * start.y - end.y * start.x) /
    Math.sqrt(dx * dx + dy * dy)
  );
}

export function boundPenInk(ink: PenInk): Box {
  const [start, ...rest] = ink.points;
  if (start === undefined) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }
  let minX = start.x;
  let minY = start.y;
  let maxX = start.x;
  let maxY = start.y;
  for (const point of rest) {
    minX = Math.min(minX, point.x);
    minY = Math.min(minY, point.y);
    maxX = Math.max(maxX, point.x);
    maxY = Math.max(maxY, point.y);
  }
  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

export function movePenInk(ink: PenInk, offset: Offset): PenInk {
  return {
    ...ink,
    points: ink.points.map((it) => offsetPosition(it, offset)),
  };
}
