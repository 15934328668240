.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.pointHandleWrapper {
  position: absolute;
  transform: translate(-50%, -50%);
}

.pointHandle {
  width: 8px;
  height: 8px;
  border: 1px solid #000;
  border-radius: 999px;
  background-color: #fff;
}
