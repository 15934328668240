import { IconProps } from "./props";
import { FC } from "react";

export const SSizeIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M15.1665 18.1878C14.3878 18.1878 13.6625 18.0543 12.9905 17.7871C12.3291 17.52 11.7585 17.0859 11.2785 16.4849C11.1291 16.2734 11.0705 16.0786 11.1025 15.9005C11.1451 15.7113 11.2678 15.5555 11.4705 15.433C11.6625 15.3106 11.8385 15.2717 11.9985 15.3162C12.1691 15.3496 12.3398 15.4609 12.5105 15.6501C12.8305 15.9951 13.2145 16.2623 13.6625 16.4515C14.1105 16.6296 14.6278 16.7186 15.2145 16.7186C15.9611 16.7186 16.5425 16.5628 16.9585 16.2511C17.3745 15.9395 17.5825 15.4831 17.5825 14.8821C17.5825 14.5259 17.5078 14.2198 17.3585 13.9638C17.2091 13.6967 16.9105 13.4574 16.4625 13.2459C16.0145 13.0344 15.3371 12.8341 14.4305 12.6449C12.3718 12.1774 11.3425 11.081 11.3425 9.35583C11.3425 8.688 11.4971 8.10365 11.8065 7.60278C12.1158 7.10191 12.5478 6.71235 13.1025 6.43409C13.6571 6.1447 14.3025 6 15.0385 6C15.7638 6 16.4145 6.13357 16.9905 6.4007C17.5665 6.6567 18.0731 6.99617 18.5105 7.41913C18.6705 7.59722 18.7398 7.78087 18.7185 7.97009C18.6971 8.1593 18.5958 8.33183 18.4145 8.48765C18.2225 8.64348 18.0411 8.71026 17.8705 8.688C17.7105 8.66574 17.5398 8.56557 17.3585 8.38748C17.0385 8.10922 16.6918 7.89217 16.3185 7.73635C15.9451 7.56939 15.5131 7.48591 15.0225 7.48591C14.4038 7.48591 13.8865 7.64174 13.4705 7.95339C13.0651 8.25391 12.8625 8.69913 12.8625 9.28904C12.8625 9.6007 12.9265 9.88452 13.0545 10.1405C13.1931 10.3854 13.4438 10.6024 13.8065 10.7917C14.1691 10.9809 14.6971 11.1534 15.3905 11.3092C16.7131 11.5986 17.6625 12.0327 18.2385 12.6115C18.8251 13.1903 19.1185 13.9416 19.1185 14.8654C19.1185 15.5777 18.9478 16.1843 18.6065 16.6852C18.2758 17.175 17.8118 17.5478 17.2145 17.8038C16.6278 18.0598 15.9451 18.1878 15.1665 18.1878Z"
        fill="#ffffff"
      />
    </svg>
  );
};
