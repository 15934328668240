import { Position } from "../position";
import {
  Box,
  moveBoxBottomLeftCorner,
  moveBoxBottomRightCorner,
  moveBoxTopLeftCorner,
  moveBoxTopRightCorner,
} from "../box";
import { Offset } from "../offset";
import { Size } from "../style";

export interface RedactInk {
  type: "redact";
  kind: "filter";
  size: Size;
  x: number;
  y: number;
  width: number;
  height: number;
}

export function createPreviewRedactInk(size: Size, pos: Position): RedactInk {
  return { type: "redact", kind: "filter", size: size, x: pos.x, y: pos.y, width: 0, height: 0 };
}

export function updatePreviewRedactInk(ink: RedactInk, pos: Position): RedactInk {
  return {
    ...ink,
    width: pos.x - ink.x,
    height: pos.y - ink.y,
  };
}

export function finalizePreviewRedactInk(ink: RedactInk): RedactInk {
  return ink;
}

export function boundRedactInk(ink: RedactInk): Box {
  return {
    x: ink.x,
    y: ink.y,
    width: ink.width,
    height: ink.height,
  };
}

export function moveRedactInk(ink: RedactInk, offset: Offset): RedactInk {
  return {
    ...ink,
    x: ink.x + offset.deltaX,
    y: ink.y + offset.deltaY,
  };
}

export function moveRedactInkTopLeftCorner(
  ink: RedactInk,
  offset: Offset,
  keepRatio: boolean,
): RedactInk {
  const box = moveBoxTopLeftCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveRedactInkTopRightCorner(
  ink: RedactInk,
  offset: Offset,
  keepRatio: boolean,
): RedactInk {
  const box = moveBoxTopRightCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveRedactInkBottomRightCorner(
  ink: RedactInk,
  offset: Offset,
  keepRatio: boolean,
): RedactInk {
  const box = moveBoxBottomRightCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveRedactInkBottomLeftCorner(
  ink: RedactInk,
  offset: Offset,
  keepRatio: boolean,
): RedactInk {
  const box = moveBoxBottomLeftCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveRedactInkTopEdge(ink: RedactInk, offset: Offset): RedactInk {
  return { ...ink, y: ink.y + offset.deltaY, height: ink.height - offset.deltaY };
}

export function moveRedactInkRightEdge(ink: RedactInk, offset: Offset): RedactInk {
  return { ...ink, width: ink.width + offset.deltaX };
}

export function moveRedactInkBottomEdge(ink: RedactInk, offset: Offset): RedactInk {
  return { ...ink, height: ink.height + offset.deltaY };
}

export function moveRedactInkLeftEdge(ink: RedactInk, offset: Offset): RedactInk {
  return { ...ink, x: ink.x + offset.deltaX, width: ink.width - offset.deltaX };
}
