import { Position } from "../position";
import {
  Box,
  moveBoxBottomEdge,
  moveBoxBottomLeftCorner,
  moveBoxBottomRightCorner,
  moveBoxLeftEdge,
  moveBoxRightEdge,
  moveBoxTopEdge,
  moveBoxTopLeftCorner,
  moveBoxTopRightCorner,
} from "../box";
import { Offset } from "../offset";
import { Style } from "../style";

export interface RectInk {
  type: "rect";
  kind: "drawing";
  style: Style;
  x: number;
  y: number;
  width: number;
  height: number;
}

export function createPreviewRectInk(style: Style, pos: Position): RectInk {
  return { type: "rect", kind: "drawing", style: style, x: pos.x, y: pos.y, width: 0, height: 0 };
}

export function updatePreviewRectInk(ink: RectInk, pos: Position): RectInk {
  return {
    ...ink,
    x: ink.x,
    y: ink.y,
    width: pos.x - ink.x,
    height: pos.y - ink.y,
  };
}

export function finalizePreviewRectInk(ink: RectInk): RectInk | undefined {
  if (ink.width === 0 && ink.height === 0) {
    return undefined;
  }
  return ink;
}

export function boundRectInk(ink: RectInk): Box {
  return {
    x: ink.x,
    y: ink.y,
    width: ink.width,
    height: ink.height,
  };
}

export function moveRectInk(ink: RectInk, offset: Offset): RectInk {
  return {
    ...ink,
    x: ink.x + offset.deltaX,
    y: ink.y + offset.deltaY,
  };
}

export function moveRectInkTopLeftCorner(
  ink: RectInk,
  offset: Offset,
  keepRatio: boolean,
): RectInk {
  const box = moveBoxTopLeftCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveRectInkTopRightCorner(
  ink: RectInk,
  offset: Offset,
  keepRatio: boolean,
): RectInk {
  const box = moveBoxTopRightCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveRectInkBottomRightCorner(
  ink: RectInk,
  offset: Offset,
  keepRatio: boolean,
): RectInk {
  const box = moveBoxBottomRightCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveRectInkBottomLeftCorner(
  ink: RectInk,
  offset: Offset,
  keepRatio: boolean,
): RectInk {
  const box = moveBoxBottomLeftCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveRectInkTopEdge(ink: RectInk, offset: Offset): RectInk {
  const box = moveBoxTopEdge(ink, offset);
  return { ...ink, ...box };
}

export function moveRectInkRightEdge(ink: RectInk, offset: Offset): RectInk {
  const box = moveBoxRightEdge(ink, offset);
  return { ...ink, ...box };
}

export function moveRectInkBottomEdge(ink: RectInk, offset: Offset): RectInk {
  const box = moveBoxBottomEdge(ink, offset);
  return { ...ink, ...box };
}

export function moveRectInkLeftEdge(ink: RectInk, offset: Offset): RectInk {
  const box = moveBoxLeftEdge(ink, offset);
  return { ...ink, ...box };
}
