import { FC } from "react";
import styles from "./ToolBar.module.scss";
import { RectToolIcon } from "../views/icons/RectToolIcon";
import { TextToolIcon } from "../views/icons/TextToolIcon";
import { PenToolIcon } from "../views/icons/PenToolIcon";
import { ArrowToolIcon } from "../views/icons/ArrowToolIcon";
import { NumberToolIcon } from "../views/icons/NumberToolIcon";
import { BlurToolIcon } from "../views/icons/BlurToolIcon";
import { PixelateToolIcon } from "../views/icons/PixelateToolIcon";
import { RedactToolIcon } from "../views/icons/RedactToolIcon";
import { Tool } from "../model/tool";
import { Color, Size, Style } from "../model/style";
import { ColorSelect } from "./ColorSelect";
import { SizeSelect } from "./SizeSelect";
import { Tooltip } from "../views/Tooltip";
import { useTexts } from "../helpers/texts";

const textMap = {
  rectangle: {
    en: "Rectangle",
    ja: "長方形",
  },
  text: {
    en: "Text",
    ja: "テキスト",
  },
  pen: {
    en: "Pen",
    ja: "ペン",
  },
  arrow: {
    en: "Arrow",
    ja: "矢印",
  },
  number: {
    en: "Number",
    ja: "数字",
  },
  blur: {
    en: "Blur",
    ja: "ぼかし",
  },
  pixelate: {
    en: "Pixelate",
    ja: "モザイク",
  },
  redact: {
    en: "Redact",
    ja: "墨塗り",
  },
};

interface Props {
  tool: Tool | undefined;
  style: Style;
  disabled?: boolean;
  onColorChange: (color: Color) => void;
  onSizeChange: (size: Size) => void;
  onToolChange: (tool: Tool) => void;
}

export const ToolBar: FC<Props> = (props) => {
  const texts = useTexts(textMap);

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <ColorSelect
          color={props.style.color}
          size={props.style.size}
          disabled={props.disabled}
          onChange={props.onColorChange}
        />
        <SizeSelect
          size={props.style.size}
          color={props.style.color}
          disabled={props.disabled}
          onChange={props.onSizeChange}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.buttons}>
        <div>
          <Tooltip label={texts.rectangle} shortcut="R" sideOffset={16}>
            <button
              disabled={props.disabled}
              data-active={props.tool === "rect"}
              className={styles.button}
              onClick={() => props.onToolChange("rect")}
            >
              <RectToolIcon />
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip label={texts.text} shortcut="T" sideOffset={16}>
            <button
              disabled={props.disabled}
              data-active={props.tool === "text"}
              className={styles.button}
              onClick={() => props.onToolChange("text")}
            >
              <TextToolIcon />
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip label={texts.pen} shortcut="P" sideOffset={16}>
            <button
              disabled={props.disabled}
              data-active={props.tool === "pen"}
              className={styles.button}
              onClick={() => props.onToolChange("pen")}
            >
              <PenToolIcon />
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip label={texts.arrow} shortcut="A" sideOffset={16}>
            <button
              disabled={props.disabled}
              data-active={props.tool === "arrow"}
              className={styles.button}
              onClick={() => props.onToolChange("arrow")}
            >
              <ArrowToolIcon />
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip label={texts.number} shortcut="N" sideOffset={16}>
            <button
              disabled={props.disabled}
              data-active={props.tool === "number"}
              className={styles.button}
              onClick={() => props.onToolChange("number")}
            >
              <NumberToolIcon />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.buttons}>
        <div>
          <Tooltip label={texts.blur} shortcut="B" sideOffset={16}>
            <button
              disabled={props.disabled}
              data-active={props.tool === "blur"}
              className={styles.button}
              onClick={() => props.onToolChange("blur")}
            >
              <BlurToolIcon />
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip label={texts.pixelate} shortcut="X" sideOffset={16}>
            <button
              disabled={props.disabled}
              data-active={props.tool === "pixelate"}
              className={styles.button}
              onClick={() => props.onToolChange("pixelate")}
            >
              <PixelateToolIcon />
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip label={texts.redact} shortcut="D" sideOffset={16}>
            <button
              disabled={props.disabled}
              data-active={props.tool === "redact"}
              className={styles.button}
              onClick={() => props.onToolChange("redact")}
            >
              <RedactToolIcon />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
