.container {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background: #ffffff;

  &[data-orientation="nwse"] {
    cursor: nwse-resize;
  }

  &[data-orientation="nesw"] {
    cursor: nesw-resize;
  }
}
