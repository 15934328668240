import { getPngDpi } from "../lib/png-dpi";

export interface Png {
  bitmap: ImageBitmap;
  density: number;
}

export async function loadPng(file: File): Promise<Png | undefined> {
  if (file.type !== "image/png") {
    return undefined;
  }
  const image = await file.arrayBuffer();
  const dpi = getPngDpi(image)?.x ?? 72;
  const density = dpi / 72;
  const bitmap = await createImageBitmap(file);
  return { bitmap: bitmap, density: density };
}
