.content {
  padding: 8px;
  background-color: var(--color-gray-0);
  border: 1px solid var(--color-gray-1);
  border-radius: 16px;
  font: var(--font-body);
  font-size: 14px;
  box-shadow: var(--shadow-bottom);
  z-index: 1;
}

.item {
  display: grid;
  grid: auto / 1fr auto;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  color: var(--color-gray-10);
  border-radius: 8px;
  cursor: pointer;

  &[data-highlighted] {
    background-color: var(--color-gray-1);
    outline: none;
  }
}

.shortcut {
  font: var(--font-shortcut);
  font-size: 14px;
  color: var(--color-gray-9);
}

.separator {
  height: 1px;
  margin: 8px 0;
  background-color: var(--color-gray-1);
}
