.trigger {
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  padding: 0;
  border: none;
  border-radius: 8px;
  background: none;
  cursor: pointer;

  &:hover:not(&:disabled) {
    background-color: var(--color-gray-1);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.circle {
  --size: 20px;
  width: var(--size);
  height: var(--size);
  border-radius: 999px;

  &[data-black="true"] {
    border: 2px solid var(--color-gray-10);
  }

  &[data-size="xxs"] {
    --size: 4px;
  }
  &[data-size="xs"] {
    --size: 6px;
  }
  &[data-size="s"] {
    --size: 8px;
  }
  &[data-size="m"] {
    --size: 10px;
  }
  &[data-size="l"] {
    --size: 12px;
  }
  &[data-size="xl"] {
    --size: 14px;
  }
  &[data-size="xxl"] {
    --size: 16px;
  }
}

.content {
  padding: 16px;
  background-color: var(--color-gray-0);
  border-radius: 8px;
  box-shadow: var(--shadow-bottom);
  z-index: 1;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item {
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
  padding: 0;
  border: none;
  border-radius: 8px;
  outline-offset: -2px;
  background: none;
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-1);
  }
}
