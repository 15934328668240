import { Tool } from "./model/tool";

type Event =
  | { name: "export"; to: "clipboard" | "file"; via: "menu" | "shortcut" }
  | { name: "start"; by: "select" | "paste" }
  | { name: "draw"; tool: Tool };

export async function trackEvent(event: Event): Promise<void> {
  const { name, ...props } = event;
  if (typeof umami !== "undefined") {
    await umami.track(name, props);
  }
}
