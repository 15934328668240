import { IconProps } from "./props";
import { FC } from "react";

export const ArrowToolIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <mask
        id="mask0_264_183"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_264_183)">
        <path
          d="M5.7146 18.2979V8.8989H7.1136V15.9134L18.3173 4.7019L19.2981 5.68265L8.09435 16.8989H15.1136V18.2979H5.7146Z"
          fill="#A3A3A3"
        />
      </g>
    </svg>
  );
};
