import { Position, ViewportPosition } from "./position";

export type Offset = { deltaX: number; deltaY: number };

export function offsetOf(pos1: Position, pos2: Position): Offset {
  return {
    deltaX: pos2.x - pos1.x,
    deltaY: pos2.y - pos1.y,
  };
}

export function invertOffset(offset: Offset): Offset {
  return { deltaX: -offset.deltaX, deltaY: -offset.deltaY };
}

export function addOffset(offset1: Offset, offset2: Offset): Offset {
  return {
    deltaX: offset1.deltaX + offset2.deltaX,
    deltaY: offset1.deltaY + offset2.deltaY,
  };
}

export function scaleOffset(offset: Offset, zoom: number): Offset {
  return { deltaX: offset.deltaX * zoom, deltaY: offset.deltaY * zoom };
}

export type ViewportOffset = { viewportDeltaX: number; viewportDeltaY: number };

export function viewportOffsetOf(pos1: ViewportPosition, pos2: ViewportPosition): ViewportOffset {
  return {
    viewportDeltaX: pos2.viewportX - pos1.viewportX,
    viewportDeltaY: pos2.viewportY - pos1.viewportY,
  };
}

export function fromViewportOffset(offset: ViewportOffset, zoom: number): Offset {
  return { deltaX: offset.viewportDeltaX / zoom, deltaY: offset.viewportDeltaY / zoom };
}
