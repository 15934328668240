import {
  MouseEvent as ReactMouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
} from "react";

export function useDrag(handlers: {
  onDragStart?: () => void;
  onDragMove?: (offset: { deltaX: number; deltaY: number }, event: MouseEvent) => void;
  onDragEnd?: () => void;
}): MouseEventHandler {
  const { onDragStart, onDragMove, onDragEnd } = handlers;
  const sessionRef = useRef<{ pageX: number; pageY: number }>();

  const handleMouseDown = useCallback(
    (e: ReactMouseEvent) => {
      e.stopPropagation();
      sessionRef.current = { pageX: e.pageX, pageY: e.pageY };
      onDragStart?.();
    },
    [onDragStart],
  );

  const onDragRef = useRef(onDragMove);
  useEffect(() => {
    onDragRef.current = onDragMove;
  }, [onDragMove]);
  useEffect(() => {
    const handle = (e: MouseEvent) => {
      if (sessionRef.current === undefined) {
        return;
      }
      e.stopPropagation();
      onDragRef.current?.(
        {
          deltaX: e.pageX - sessionRef.current.pageX,
          deltaY: e.pageY - sessionRef.current.pageY,
        },
        e,
      );
    };
    window.addEventListener("mousemove", handle);
    return () => {
      window.removeEventListener("mousemove", handle);
    };
  }, []);

  useEffect(() => {
    const handle = (_e: MouseEvent) => {
      sessionRef.current = undefined;
      onDragEnd?.();
    };
    window.addEventListener("mouseup", handle);
    return () => {
      window.removeEventListener("mouseup", handle);
    };
  }, [onDragEnd]);

  return handleMouseDown;
}
