import { FC } from "react";
import * as Select from "@radix-ui/react-select";
import styles from "./ColorSelect.module.scss";
import { Color, Size } from "../model/style";

interface Props {
  color: Color;
  size: Size;
  disabled?: boolean;
  onChange: (color: Color) => void;
}

export const ColorSelect: FC<Props> = (props) => {
  return (
    <Select.Root value={props.color} disabled={props.disabled} onValueChange={props.onChange}>
      <Select.Trigger className={styles.trigger}>
        <div
          data-size={props.size}
          data-black={props.color === "black"}
          style={{ backgroundColor: `var(--color-${props.color})` }}
          className={styles.circle}
        />
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          position="popper"
          side="bottom"
          align="center"
          sideOffset={16}
          className={styles.content}
        >
          <Select.Arrow />
          <Select.Viewport className={styles.items}>
            <Select.Item value="pink" className={styles.item}>
              <div style={{ backgroundColor: "var(--color-pink)" }} className={styles.circle} />
            </Select.Item>
            <Select.Item value="red" className={styles.item}>
              <div style={{ backgroundColor: "var(--color-red)" }} className={styles.circle} />
            </Select.Item>
            <Select.Item value="orange" className={styles.item}>
              <div style={{ backgroundColor: "var(--color-orange)" }} className={styles.circle} />
            </Select.Item>
            <Select.Item value="green" className={styles.item}>
              <div style={{ backgroundColor: "var(--color-green)" }} className={styles.circle} />
            </Select.Item>
            <Select.Item value="cyan" className={styles.item}>
              <div style={{ backgroundColor: "var(--color-cyan)" }} className={styles.circle} />
            </Select.Item>
            <Select.Item value="purple" className={styles.item}>
              <div style={{ backgroundColor: "var(--color-purple)" }} className={styles.circle} />
            </Select.Item>
            <Select.Item value="white" className={styles.item}>
              <div style={{ backgroundColor: "var(--color-white)" }} className={styles.circle} />
            </Select.Item>
            <Select.Item value="black" className={styles.item}>
              <div
                data-black={true}
                style={{ backgroundColor: "var(--color-black)" }}
                className={styles.circle}
              />
            </Select.Item>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
