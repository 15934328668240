.toast {
  position: relative;
  padding: 16px 24px;
  color: var(--color-gray-10);
  background-color: black;
  border-radius: 8px;
  box-shadow: var(--shadow-bottom);
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  background: none;
}

.viewport {
  position: fixed;
  left: 50%;
  bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 0;
  list-style: none;
  transform: translateX(-50%);
}
