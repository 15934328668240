import { IconProps } from "./props";
import { FC } from "react";

export const AlignLeftIcon: FC<IconProps> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_463_9744"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
        className={props.className}
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_463_9744)">
        <path
          d="M3 18.6465V16.6465H15V18.6465H3ZM3 12.6465V10.6465H15V12.6465H3ZM3 6.64648V4.64648H21V6.64648H3Z"
          fill="#A3A3A3"
        />
      </g>
    </svg>
  );
};
