import { Position } from "../position";
import {
  Box,
  moveBoxBottomEdge,
  moveBoxBottomLeftCorner,
  moveBoxBottomRightCorner,
  moveBoxLeftEdge,
  moveBoxRightEdge,
  moveBoxTopEdge,
  moveBoxTopLeftCorner,
  moveBoxTopRightCorner,
} from "../box";
import { Offset } from "../offset";
import { Size } from "../style";

export interface BlurInk {
  type: "blur";
  kind: "filter";
  size: Size;
  x: number;
  y: number;
  width: number;
  height: number;
}

export function createPreviewBlurInk(size: Size, pos: Position): BlurInk {
  return { type: "blur", kind: "filter", size: size, x: pos.x, y: pos.y, width: 0, height: 0 };
}

export function updatePreviewBlurInk(ink: BlurInk, pos: Position): BlurInk {
  return {
    ...ink,
    width: pos.x - ink.x,
    height: pos.y - ink.y,
  };
}

export function finalizePreviewBlurInk(ink: BlurInk): BlurInk {
  return ink;
}

export function boundBlurInk(ink: BlurInk): Box {
  return {
    x: ink.x,
    y: ink.y,
    width: ink.width,
    height: ink.height,
  };
}

export function moveBlurInk(ink: BlurInk, offset: Offset): BlurInk {
  return {
    ...ink,
    x: ink.x + offset.deltaX,
    y: ink.y + offset.deltaY,
  };
}

export function moveBlurInkTopLeftCorner(
  ink: BlurInk,
  offset: Offset,
  keepRatio: boolean,
): BlurInk {
  const box = moveBoxTopLeftCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveBlurInkTopRightCorner(
  ink: BlurInk,
  offset: Offset,
  keepRatio: boolean,
): BlurInk {
  const box = moveBoxTopRightCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveBlurInkBottomRightCorner(
  ink: BlurInk,
  offset: Offset,
  keepRatio: boolean,
): BlurInk {
  const box = moveBoxBottomRightCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveBlurInkBottomLeftCorner(
  ink: BlurInk,
  offset: Offset,
  keepRatio: boolean,
): BlurInk {
  const box = moveBoxBottomLeftCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function moveBlurInkTopEdge(ink: BlurInk, offset: Offset): BlurInk {
  const box = moveBoxTopEdge(ink, offset);
  return { ...ink, ...box };
}

export function moveBlurInkRightEdge(ink: BlurInk, offset: Offset): BlurInk {
  const box = moveBoxRightEdge(ink, offset);
  return { ...ink, ...box };
}

export function moveBlurInkBottomEdge(ink: BlurInk, offset: Offset): BlurInk {
  const box = moveBoxBottomEdge(ink, offset);
  return { ...ink, ...box };
}

export function moveBlurInkLeftEdge(ink: BlurInk, offset: Offset): BlurInk {
  const box = moveBoxLeftEdge(ink, offset);
  return { ...ink, ...box };
}
