.content {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 12px 16px;
  color: var(--color-gray-10);
  background-color: var(--color-gray-0);
  border-radius: 8px;
  box-shadow: var(--shadow-bottom);
}

.shortcut {
  font: var(--font-shortcut);
  color: var(--color-gray-9);
}
