import { FC, useCallback } from "react";
import styles from "./CornerHandle.module.scss";
import { Offset } from "../model/offset";
import { useDrag } from "../lib/drag";

interface Props {
  orientation: "nesw" | "nwse";
  zoom: number;
  onDragStart?: () => void;
  onDragMove?: (offset: Offset, event: MouseEvent) => void;
  onDragEnd?: () => void;
}

export const CornerHandle: FC<Props> = (props) => {
  const { onDragMove } = props;

  const handleDragMove = useCallback(
    (offset: Offset, event: MouseEvent) => {
      onDragMove?.(
        {
          deltaX: offset.deltaX / props.zoom,
          deltaY: offset.deltaY / props.zoom,
        },
        event,
      );
    },
    [onDragMove, props.zoom],
  );

  const handleMouseDown = useDrag({
    onDragStart: props.onDragStart,
    onDragMove: handleDragMove,
    onDragEnd: props.onDragEnd,
  });

  return (
    <div
      data-orientation={props.orientation}
      className={styles.container}
      onMouseDown={handleMouseDown}
    />
  );
};
