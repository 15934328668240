.container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &[data-tool] {
    cursor: crosshair;
  }
}

.previewBox {
  position: absolute;
  border: 1px solid lightgray;
  pointer-events: none;

  &::after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    display: block;
    border: 1px dashed black;
  }
}
