import * as CRC32 from "crc-32";

type Dpi = { x: number; y: number };

export function dumpAllPngChunks(data: ArrayBuffer): void {
  if (!isPng(data)) {
    return undefined;
  }
  let offset = 8;
  while (offset < data.byteLength) {
    const chunk = readChunk(data, offset);
    if (chunk === undefined) {
      return undefined;
    }
    console.log(chunk);
    offset = chunk.end;
  }
}

export function getPngDpi(data: ArrayBuffer): Dpi | undefined {
  if (!isPng(data)) {
    return undefined;
  }
  let offset = 8;
  while (offset < data.byteLength) {
    const chunk = readChunk(data, offset);
    if (chunk === undefined) {
      return undefined;
    }
    if (chunk.type === "pHYs") {
      const phys = parsePhysChunk(chunk.data);
      if (phys === undefined) {
        return undefined;
      }
      if (phys.unit !== "metre") {
        return undefined;
      }
      return {
        x: Math.round(phys.x / 39.3701),
        y: Math.round(phys.y / 39.3701),
      };
    }
    offset = chunk.end;
  }
  return undefined;
}

export function setPngDpi(data: ArrayBuffer, dpi: Dpi): ArrayBuffer {
  if (!isPng(data)) {
    return data;
  }
  let offset = 8;
  let ihdrChunk = undefined;
  let physChunk = undefined;
  while (offset < data.byteLength) {
    const chunk = readChunk(data, offset);
    if (chunk === undefined) {
      return data;
    }
    if (chunk.type === "IHDR") {
      ihdrChunk = chunk;
    }
    if (chunk.type === "pHYs") {
      physChunk = chunk;
      break;
    }
    offset = chunk.end;
  }
  const newPhysChunk = createPhysChunk(
    Math.round(dpi.x * 39.3701),
    Math.round(dpi.y * 39.3701),
    "metre",
  );
  if (physChunk !== undefined) {
    const head = data.slice(0, physChunk.start);
    const tail = data.slice(physChunk.end);
    return concat(head, newPhysChunk, tail);
  }
  if (ihdrChunk === undefined) {
    // IHDR chunk is required
    return data;
  }
  const head = data.slice(0, ihdrChunk.end);
  const tail = data.slice(ihdrChunk.end);
  return concat(head, newPhysChunk, tail);
}

function isPng(data: ArrayBuffer): boolean {
  // https://www.w3.org/TR/2003/REC-PNG-20031110/#5PNG-file-signature
  const array = new Uint8Array(data);
  return (
    array[0] === 137 &&
    array[1] === 80 &&
    array[2] === 78 &&
    array[3] === 71 &&
    array[4] === 13 &&
    array[5] === 10 &&
    array[6] === 26 &&
    array[7] === 10
  );
}

type Chunk = { type: string; data: ArrayBuffer; start: number; end: number };

function readChunk(data: ArrayBuffer, offset: number): Chunk | undefined {
  // https://www.w3.org/TR/2003/REC-PNG-20031110/#5Chunk-layout
  const length = new DataView(data).getUint32(offset);
  const type = parseChunkType(data.slice(offset + 4, offset + 8));
  if (type === undefined) {
    return undefined;
  }
  const chunkData = data.slice(offset + 8, offset + 8 + length);
  return {
    type: type,
    data: chunkData,
    start: offset,
    end: offset + 12 + length,
  };
}

function parseChunkType(data: ArrayBuffer): string | undefined {
  const array = new Uint8Array(data);
  if (
    array.length !== 4 ||
    !array.every((it) => (it >= 65 && it <= 90) || (it >= 97 && it <= 122))
  ) {
    return undefined;
  }
  return String.fromCharCode(...array);
}

function parsePhysChunk(
  data: ArrayBuffer,
): { x: number; y: number; unit?: "metre" } | undefined {
  const array = new Uint8Array(data);
  if (array.length !== 9) {
    return undefined;
  }
  return {
    x: new DataView(data).getUint32(0),
    y: new DataView(data).getUint32(4),
    unit: array[8] === 1 ? "metre" : undefined,
  };
}

function createPhysChunk(x: number, y: number, unit?: "metre"): ArrayBuffer {
  const array = new Uint8Array(21);
  const view = new DataView(array.buffer);
  view.setUint32(0, 9);
  array[4] = 112;
  array[5] = 72;
  array[6] = 89;
  array[7] = 115;
  view.setUint32(8, x);
  view.setUint32(12, y);
  array[16] = unit === "metre" ? 1 : 0;
  view.setUint32(17, CRC32.buf(array.subarray(4, 17)));
  return array.buffer;
}

function concat(...buffers: ArrayBuffer[]): ArrayBuffer {
  const length = buffers.reduce((acc, it) => acc + it.byteLength, 0);
  const array = new Uint8Array(length);
  let offset = 0;
  for (const buf of buffers) {
    array.set(new Uint8Array(buf), offset);
    offset += buf.byteLength;
  }
  return array.buffer;
}
