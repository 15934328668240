import { FC, ReactNode } from "react";
import styles from "./Tooltip.module.scss";
import * as RadixTooltip from "@radix-ui/react-tooltip";

interface Props {
  label: string;
  shortcut?: string;
  side?: "top" | "right" | "bottom" | "left";
  sideOffset?: number;
  align?: "start" | "center" | "end";
  alignOffset?: number;
  children: ReactNode;
}

export const Tooltip: FC<Props> = (props) => {
  return (
    <RadixTooltip.Root>
      <RadixTooltip.Trigger asChild>{props.children}</RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          side={props.side}
          sideOffset={props.sideOffset}
          align={props.align}
          alignOffset={props.alignOffset}
          className={styles.content}
        >
          {props.label}
          {props.shortcut !== undefined && (
            <span className={styles.shortcut}>{props.shortcut}</span>
          )}
          <RadixTooltip.Arrow />
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

export const TooltipProvider: FC<{ children: ReactNode }> = (props) => {
  return (
    <RadixTooltip.TooltipProvider delayDuration={500} skipDelayDuration={300}>
      {props.children}
    </RadixTooltip.TooltipProvider>
  );
};
