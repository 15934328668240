import styles from "./ItemEditor.module.scss";
import { Item } from "../model/item";
import { Offset } from "../model/offset";
import {
  FC,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { boundInk, Ink } from "../model/tool";
import { normalizeBox, toViewportBox } from "../model/box";
import { InkEditor } from "./InkEditor";
import { ItemContextMenu, ItemContextMenuItem } from "./ItemContextMenu";

interface ItemEditorProps {
  item: Item;
  pan: Offset;
  zoom: number;
  isActive: boolean;
  onClick: (shift: boolean) => void;
  onDrag: (offset: Offset, multiple: boolean) => void;
  onChange: (item: Item) => void;
  onDelete: () => void;
  onCancelCreate: () => void;
  onContextMenuOpenChange: (open: boolean) => void;
  onContextMenuItemSelect: (menuItem: ItemContextMenuItem) => void;
}

export const ItemEditor: FC<ItemEditorProps> = (props) => {
  const { onClick, onDrag, onChange, onDelete } = props;
  const dragSessionRef = useRef<{ pageX: number; pageY: number; moved: boolean }>();

  const viewportBox = useMemo(() => {
    const boundingBox = normalizeBox(boundInk(props.item.ink));
    return toViewportBox(boundingBox, props.pan, props.zoom);
  }, [props.item.ink, props.pan, props.zoom]);

  const handleMouseDown = useCallback((e: ReactMouseEvent) => {
    if (e.button !== 0) {
      return;
    }
    e.stopPropagation();
    dragSessionRef.current = {
      pageX: e.pageX,
      pageY: e.pageY,
      moved: false,
    };
  }, []);

  const onDragRef = useRef(onDrag);
  useLayoutEffect(() => {
    onDragRef.current = onDrag;
  }, [onDrag]);
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const dragPos = dragSessionRef.current;
      if (dragPos === undefined) {
        return;
      }
      e.stopPropagation();
      onDragRef.current?.(
        {
          deltaX: (e.pageX - dragPos.pageX) / props.zoom,
          deltaY: (e.pageY - dragPos.pageY) / props.zoom,
        },
        e.shiftKey,
      );
      dragSessionRef.current = {
        pageX: e.pageX,
        pageY: e.pageY,
        moved: true,
      };
    };
    document.addEventListener("mousemove", handler);
    return () => {
      document.removeEventListener("mousemove", handler);
    };
  }, [props.zoom]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const dragSession = dragSessionRef.current;
      if (dragSession === undefined) {
        return;
      }
      e.stopPropagation();
      dragSessionRef.current = undefined;
      if (!dragSession.moved) {
        onClick(e.shiftKey);
      }
    };
    document.addEventListener("mouseup", handler);
    return () => {
      document.removeEventListener("mouseup", handler);
    };
  }, [onClick]);

  const handleInkChange = useCallback(
    (ink: Ink | undefined) => {
      if (ink === undefined) {
        onDelete();
      } else {
        onChange({ ...props.item, ink });
      }
    },
    [props.item, onChange, onDelete],
  );

  return (
    <ItemContextMenu
      onOpenChange={props.onContextMenuOpenChange}
      onMenuItemSelect={props.onContextMenuItemSelect}
    >
      <div
        data-active={props.isActive}
        style={{
          top: viewportBox.viewportY,
          left: viewportBox.viewportX,
          width: viewportBox.viewportWidth,
          height: viewportBox.viewportHeight,
        }}
        className={styles.container}
        onMouseDown={handleMouseDown}
      >
        {props.isActive && (
          <InkEditor
            ink={props.item.ink}
            pan={props.pan}
            zoom={props.zoom}
            onChange={handleInkChange}
            onCancelCreate={props.onCancelCreate}
          />
        )}
      </div>
    </ItemContextMenu>
  );
};
