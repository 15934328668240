import { IconProps } from "./props";
import { FC } from "react";

export const XxsSizeIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M24.979 18.1878C24.2003 18.1878 23.475 18.0543 22.803 17.7871C22.1416 17.52 21.571 17.0859 21.091 16.4849C20.9416 16.2734 20.883 16.0786 20.915 15.9005C20.9576 15.7113 21.0803 15.5555 21.283 15.433C21.475 15.3106 21.651 15.2717 21.811 15.3162C21.9816 15.3496 22.1523 15.4609 22.323 15.6501C22.643 15.9951 23.027 16.2623 23.475 16.4515C23.923 16.6296 24.4403 16.7186 25.027 16.7186C25.7736 16.7186 26.355 16.5628 26.771 16.2511C27.187 15.9395 27.395 15.4831 27.395 14.8821C27.395 14.5259 27.3203 14.2198 27.171 13.9638C27.0216 13.6967 26.723 13.4574 26.275 13.2459C25.827 13.0344 25.1496 12.8341 24.243 12.6449C22.1843 12.1774 21.155 11.081 21.155 9.35583C21.155 8.688 21.3096 8.10365 21.619 7.60278C21.9283 7.10191 22.3603 6.71235 22.915 6.43409C23.4696 6.1447 24.115 6 24.851 6C25.5763 6 26.227 6.13357 26.803 6.4007C27.379 6.6567 27.8856 6.99617 28.323 7.41913C28.483 7.59722 28.5523 7.78087 28.531 7.97009C28.5096 8.1593 28.4083 8.33183 28.227 8.48765C28.035 8.64348 27.8536 8.71026 27.683 8.688C27.523 8.66574 27.3523 8.56557 27.171 8.38748C26.851 8.10922 26.5043 7.89217 26.131 7.73635C25.7576 7.56939 25.3256 7.48591 24.835 7.48591C24.2163 7.48591 23.699 7.64174 23.283 7.95339C22.8776 8.25391 22.675 8.69913 22.675 9.28904C22.675 9.6007 22.739 9.88452 22.867 10.1405C23.0056 10.3854 23.2563 10.6024 23.619 10.7917C23.9816 10.9809 24.5096 11.1534 25.203 11.3092C26.5256 11.5986 27.475 12.0327 28.051 12.6115C28.6376 13.1903 28.931 13.9416 28.931 14.8654C28.931 15.5777 28.7603 16.1843 28.419 16.6852C28.0883 17.175 27.6243 17.5478 27.027 17.8038C26.4403 18.0598 25.7576 18.1878 24.979 18.1878Z"
        fill="#ffffff"
      />
      <path
        d="M11.5824 17.9709C11.209 17.9709 10.985 17.8707 10.9104 17.6704C10.8357 17.47 10.873 17.2474 11.0224 17.0025L14.1744 11.8436L11.3424 7.25228C11.129 6.9295 11.0757 6.6902 11.1824 6.53437C11.289 6.36741 11.5237 6.28394 11.8864 6.28394C12.2917 6.28394 12.5957 6.46202 12.7984 6.8182L14.9744 10.4578L17.1344 6.8182C17.337 6.46202 17.641 6.28394 18.0464 6.28394C18.761 6.28394 18.9424 6.60672 18.5904 7.25228L15.7744 11.8436L18.9264 17.0025C19.097 17.2808 19.1344 17.5145 19.0384 17.7038C18.953 17.8818 18.7237 17.9709 18.3504 17.9709C17.945 17.9709 17.641 17.7928 17.4384 17.4366L14.9744 13.246L12.4944 17.4366C12.2917 17.7928 11.9877 17.9709 11.5824 17.9709Z"
        fill="#ffffff"
      />
      <path
        d="M1.76988 17.9709C1.39655 17.9709 1.17255 17.8707 1.09788 17.6704C1.02321 17.47 1.06055 17.2474 1.20988 17.0025L4.36188 11.8436L1.52988 7.25228C1.31655 6.9295 1.26321 6.6902 1.36988 6.53437C1.47655 6.36741 1.71121 6.28394 2.07388 6.28394C2.47921 6.28394 2.78321 6.46202 2.98588 6.8182L5.16188 10.4578L7.32188 6.8182C7.52455 6.46202 7.82855 6.28394 8.23388 6.28394C8.94855 6.28394 9.12988 6.60672 8.77788 7.25228L5.96188 11.8436L9.11388 17.0025C9.28455 17.2808 9.32188 17.5145 9.22588 17.7038C9.14055 17.8818 8.91121 17.9709 8.53788 17.9709C8.13255 17.9709 7.82855 17.7928 7.62588 17.4366L5.16188 13.246L2.68188 17.4366C2.47921 17.7928 2.17521 17.9709 1.76988 17.9709Z"
        fill="#ffffff"
      />
    </svg>
  );
};
