.container {
  width: 100%;
  height: 100%;
}

.cornerHandleWrapper {
  position: absolute;
  transform: translate(-50%, -50%);
}

.horizontalHandleWrapper {
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
}

.verticalHandleWrapper {
  position: absolute;
  height: 100%;
  transform: translateX(-50%);
}
