import { IconProps } from "./props";
import { FC } from "react";

export const NumberToolIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <mask
        id="mask0_264_186"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_264_186)">
        <path
          d="M12.0022 21.2979C10.7163 21.2979 9.50749 21.0536 8.37565 20.5649C7.24365 20.0761 6.25899 19.4127 5.42165 18.5749C4.58449 17.7371 3.9219 16.7533 3.4339 15.6237C2.9459 14.4938 2.7019 13.2867 2.7019 12.0022C2.7019 10.7163 2.94624 9.50749 3.4349 8.37565C3.92374 7.24365 4.58707 6.25899 5.4249 5.42165C6.26274 4.58449 7.24649 3.9219 8.37615 3.4339C9.50599 2.9459 10.7132 2.7019 11.9977 2.7019C13.2835 2.7019 14.4923 2.94624 15.6242 3.4349C16.7562 3.92374 17.7408 4.58707 18.5782 5.4249C19.4153 6.26274 20.0779 7.24649 20.5659 8.37615C21.0539 9.50599 21.2979 10.7132 21.2979 11.9977C21.2979 13.2835 21.0536 14.4923 20.5649 15.6242C20.0761 16.7562 19.4127 17.7408 18.5749 18.5782C17.7371 19.4153 16.7533 20.0779 15.6237 20.5659C14.4938 21.0539 13.2867 21.2979 12.0022 21.2979ZM11.9997 19.8989C14.1978 19.8989 16.0639 19.1321 17.5979 17.5984C19.1319 16.0646 19.8989 14.1985 19.8989 12.0002C19.8989 9.80199 19.1321 7.9359 17.5984 6.4019C16.0646 4.8679 14.1985 4.1009 12.0002 4.1009C9.80199 4.1009 7.9359 4.86774 6.4019 6.4014C4.8679 7.93524 4.1009 9.80132 4.1009 11.9997C4.1009 14.1978 4.86774 16.0639 6.4014 17.5979C7.93524 19.1319 9.80132 19.8989 11.9997 19.8989ZM11.7904 16.6489H13.1894V7.3509H9.81041V8.7499H11.7904V16.6489Z"
          fill="#A3A3A3"
        />
      </g>
    </svg>
  );
};
