import { createContext, FC, Fragment, ReactNode, useContext } from "react";

type ExtraLang = "ja";
type Lang = "en" | ExtraLang;

type TextEntry = {
  [P in ExtraLang]?: string;
} & { en: string };

interface TextEntryMap {
  [key: string]: TextEntry;
}

const context = createContext<Lang>("en");

export const TextsProvider: FC<{ lang: Lang; children: ReactNode }> = (props) => {
  return <context.Provider value={props.lang}>{props.children}</context.Provider>;
};

export function useTexts<T extends TextEntryMap>(map: T): { [K in keyof T]: string } {
  const lang = useContext(context);
  return getTexts(map, lang);
}

function getTexts<T extends TextEntryMap>(textMap: T, lang: Lang): { [K in keyof T]: string } {
  // @ts-ignore
  const map: { [K in keyof T]: string } = {};
  for (const key of Object.keys(textMap) as (keyof T)[]) {
    map[key] = textMap[key][lang] ?? textMap[key].en;
  }
  return map;
}

export function rformat(text: string, ...args: ReactNode[]): ReactNode {
  const res: ReactNode[] = [];
  let s = 0;
  let f = false;
  let argIndex = 0;
  for (let i = 0; i < text.length; i++) {
    if (f && text[i] === "s") {
      res.push(text.slice(s, i - 1), <Fragment key={`arg${argIndex}`}>{args.shift()}</Fragment>);
      s = i + 1;
      f = false;
      argIndex += 1;
    }
    if (text[i] === "%") {
      if (f) {
        res.push(text.slice(s, i));
        s = i + 1;
        f = false;
      } else {
        f = true;
      }
    }
  }
  res.push(text.slice(s));
  return res;
}
