import { Position } from "../position";
import { Box } from "../box";
import { TextMetrics, TextStyle } from "pixi.js";
import { Offset } from "../offset";
import { applySize, Style } from "../style";

export interface TextInk {
  type: "text";
  kind: "drawing";
  style: Style;
  text: string;
  align: "left" | "center" | "right";
  x: number;
  y: number;
  width?: number;
}

export function createPreviewTextInk(style: Style, pos: Position): TextInk {
  return {
    type: "text",
    kind: "drawing",
    style: style,
    text: "",
    align: "left",
    x: pos.x,
    y: pos.y,
  };
}

export function updatePreviewTextInk(ink: TextInk, pos: Position): TextInk {
  return { ...ink, width: pos.x - ink.x };
}

export function finalizePreviewTextInk(ink: TextInk): TextInk | undefined {
  if (ink.text === "") {
    return undefined;
  }
  const normalized = normalize(ink);
  return {
    ...normalized,
    width: normalized.width !== undefined && normalized.width < 10 ? undefined : normalized.width,
  };
}

export function boundTextInk(ink: TextInk): Box {
  const metrics = measureTextInk(ink);
  return {
    x: ink.x,
    y: ink.y,
    width: ink.width ?? metrics.width,
    height: metrics.height,
  };
}

export function moveTextInk(ink: TextInk, offset: Offset): TextInk {
  return {
    ...ink,
    x: ink.x + offset.deltaX,
    y: ink.y + offset.deltaY,
  };
}

export function moveTextInkRightEdge(ink: TextInk, offset: Offset): TextInk {
  const matrix = measureTextInk(ink);
  return normalize({
    ...ink,
    width: (ink.width ?? matrix.width) + offset.deltaX,
  });
}

export function moveTextInkLeftEdge(ink: TextInk, offset: Offset): TextInk {
  const matrix = measureTextInk(ink);
  return normalize({
    ...ink,
    x: ink.x + offset.deltaX,
    width: (ink.width ?? matrix.width) - offset.deltaX,
  });
}

function normalize(ink: TextInk): TextInk {
  if (ink.width !== undefined && ink.width < 0) {
    return { ...ink, x: ink.x + ink.width, width: Math.abs(ink.width) };
  }
  return ink;
}

// 本来は Pixi 側で管理すべきだけど textStyleForPixi に書いたのと同じ理由でここに書かざるを得ない
const fontSizes = 32;

// モデルは Pixi に依存しないようにしたかったけどテキストの描画サイズを計算するのにどうしても必要だった
// fill （塗色）はサイズ計算には必要ないのでここでは指定せず、描画側で上書きして指定する。
export function textStyleForPixi(ink: TextInk): TextStyle {
  const fontSize = applySize(fontSizes, ink.style.size);
  return new TextStyle({
    fontFamily: ["Noto Sans", "sans-serif"],
    fontSize: fontSize,
    fontWeight: "bold",
    lineHeight: fontSize,
    align: ink.align,
    stroke: 0xffffff,
    strokeThickness: fontSize / 4,
    padding: fontSize,
    dropShadow: true,
    dropShadowAlpha: 0.2,
    dropShadowBlur: 10,
    dropShadowDistance: 0,
    lineJoin: "round",
    wordWrap: ink.width !== undefined,
    wordWrapWidth: ink.width !== undefined ? Math.abs(ink.width) : undefined,
    breakWords: ink.width !== undefined,
  });
}

export function measureTextInk(ink: TextInk): { width: number; height: number } {
  return TextMetrics.measureText(ink.text, textStyleForPixi(ink));
}
