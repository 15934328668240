import { IconProps } from "./props";
import { FC } from "react";

export const RedoIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <mask
        id="mask0_289_206"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_289_206)">
        <path
          d="M9.62122 18.5C8.15839 18.5 6.90547 18.0103 5.86247 17.0308C4.81947 16.0513 4.29797 14.8455 4.29797 13.4135C4.29797 11.9815 4.81947 10.7773 5.86247 9.801C6.90547 8.82467 8.15839 8.3365 9.62122 8.3365H16.6422L13.8597 5.55375L14.9135 4.5L19.5 9.0865L14.9135 13.673L13.8597 12.6193L16.6422 9.8365H9.62122C8.57756 9.8365 7.67947 10.1794 6.92697 10.8652C6.17431 11.5512 5.79797 12.4007 5.79797 13.4135C5.79797 14.4263 6.17431 15.2773 6.92697 15.9663C7.67947 16.6554 8.57756 17 9.62122 17H16.798V18.5H9.62122Z"
          fill="#a3a3a3"
        />
      </g>
    </svg>
  );
};
