import "./styles/globals.css";
import { createRoot } from "react-dom/client";
import { Toaster } from "./helpers/toaster";
import { App } from "./components/App";
import { TooltipProvider } from "./views/Tooltip";
import { TextsProvider } from "./helpers/texts";

const root = createRoot(document.getElementById("app")!);
root.render(
  <TextsProvider lang="en">
    <Toaster>
      <TooltipProvider>
        <App />
      </TooltipProvider>
    </Toaster>
  </TextsProvider>,
);
