.container {
  height: 56px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 24px;
  background-color: var(--color-gray-0);
  border-radius: 999px;
  box-shadow: var(--shadow-bottom);
}

.divider {
  width: 1px;
  height: 16px;
  background-color: var(--color-gray-1);
}

.buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.button {
  position: relative;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  &:hover:not(&:disabled),
  &[data-active="true"] {
    svg {
      path,
      rect {
        fill: var(--color-gray-10);
      }
    }
  }

  &[data-active="true"]::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 24px;
    height: 2px;
    background-color: var(--color-gray-10);
    border-radius: 2px 2px 0 0;
    transform: translate(-50%, 8px);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
