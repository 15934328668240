import { IconProps } from "./props";
import { FC } from "react";

export const TextToolIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <mask
        id="mask0_289_153"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_289_153)">
        <path
          d="M11.523 19.298V6.6652H6.21149V4.68945H18.798V6.6652H13.4865V19.298H11.523Z"
          fill="#A3A3A3"
        />
      </g>
    </svg>
  );
};
