import { FC } from "react";
import { assertNever } from "../../lib/assert-never";
import { Ink } from "../../model/tool";
import { RectInkEditor } from "./RectInkEditor";
import { BlurInkEditor } from "./BlurInkEditor";
import { PixelateInkEditor } from "./PixelateInkEditor";
import { ImageInkEditor } from "./ImageInkEditor";
import { PenInkEditor } from "./PenInkEditor";
import { Offset } from "../../model/offset";
import { TextInkEditor } from "./TextInkEditor";
import { ArrowInkEditor } from "./ArrowInkEditor";
import { RedactInkEditor } from "./RedactInkEditor";

/**
 * @package
 */
export interface InkEditorProps<T extends Ink = Ink> {
  ink: T;
  pan: Offset;
  zoom: number;
  onChange: (ink: T | undefined) => void;
  onCancelCreate: () => void;
}

export const InkEditor: FC<InkEditorProps> = (props) => {
  if (props.ink.type === "rect") {
    return <RectInkEditor {...props} ink={props.ink} />;
  }
  if (props.ink.type === "image") {
    return <ImageInkEditor {...props} ink={props.ink} />;
  }
  if (props.ink.type === "blur") {
    return <BlurInkEditor {...props} ink={props.ink} />;
  }
  if (props.ink.type === "pixelate") {
    return <PixelateInkEditor {...props} ink={props.ink} />;
  }
  if (props.ink.type === "text") {
    return <TextInkEditor {...props} ink={props.ink} />;
  }
  if (props.ink.type === "pen") {
    return <PenInkEditor {...props} ink={props.ink} />;
  }
  if (props.ink.type === "arrow") {
    return <ArrowInkEditor {...props} ink={props.ink} />;
  }
  if (props.ink.type === "number") {
    return null;
  }
  if (props.ink.type === "redact") {
    return <RedactInkEditor {...props} ink={props.ink} />;
  }
  assertNever(props.ink);
};
