.canvas {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("/static/pattern.png");
  background-size: 40px;
  background-repeat: repeat;
}

.welcomeWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoWrapper {
  position: absolute;
  top: 12px;
  left: 12px;
  opacity: 0.1;
  pointer-events: none;
}

.toolBarWrapper {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.menuBarWrapper {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
}

.infoWrapper {
  position: absolute;
  right: 16px;
  bottom: 16px;
  opacity: 0.1;
}

.info {
  color: var(--color-gray-10);
  text-align: right;
}

.textModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-content: center;
  background-color: #00000080;

  textarea {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: white;
    border: none;
    background: none;
  }
}
