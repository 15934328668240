.container {
  position: absolute;
  box-sizing: border-box;
  user-select: none;
  font-size: 12px;
  word-break: break-all;

  &:hover,
  &[data-active="true"] {
    outline: 1px solid var(--color-gray-10);
    outline-offset: 0;

    &:after {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      outline: 1px solid var(--color-gray-1);
      outline-offset: -1px;
    }
  }

  &[data-active="true"] {
    z-index: 1;
  }
}
