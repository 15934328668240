import { IconProps } from "./props";
import { FC } from "react";

export const EllipsisIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <mask
        id="mask0_289_210"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_289_210)">
        <path
          d="M12 19.2692C11.5875 19.2692 11.2344 19.1223 10.9408 18.8285C10.6469 18.5348 10.5 18.1817 10.5 17.7692C10.5 17.3567 10.6469 17.0035 10.9408 16.7097C11.2344 16.416 11.5875 16.2692 12 16.2692C12.4125 16.2692 12.7656 16.416 13.0592 16.7097C13.3531 17.0035 13.5 17.3567 13.5 17.7692C13.5 18.1817 13.3531 18.5348 13.0592 18.8285C12.7656 19.1223 12.4125 19.2692 12 19.2692ZM12 13.5C11.5875 13.5 11.2344 13.353 10.9408 13.0592C10.6469 12.7655 10.5 12.4125 10.5 12C10.5 11.5875 10.6469 11.2344 10.9408 10.9407C11.2344 10.6469 11.5875 10.5 12 10.5C12.4125 10.5 12.7656 10.6469 13.0592 10.9407C13.3531 11.2344 13.5 11.5875 13.5 12C13.5 12.4125 13.3531 12.7655 13.0592 13.0592C12.7656 13.353 12.4125 13.5 12 13.5ZM12 7.73071C11.5875 7.73071 11.2344 7.58388 10.9408 7.29021C10.6469 6.99638 10.5 6.64321 10.5 6.23071C10.5 5.81821 10.6469 5.46513 10.9408 5.17146C11.2344 4.87763 11.5875 4.73071 12 4.73071C12.4125 4.73071 12.7656 4.87763 13.0592 5.17146C13.3531 5.46513 13.5 5.81821 13.5 6.23071C13.5 6.64321 13.3531 6.99638 13.0592 7.29021C12.7656 7.58388 12.4125 7.73071 12 7.73071Z"
          fill="#a3a3a3"
        />
      </g>
    </svg>
  );
};
