import { Position } from "../position";
import {
  Box,
  moveBoxBottomEdge,
  moveBoxBottomLeftCorner,
  moveBoxBottomRightCorner,
  moveBoxLeftEdge,
  moveBoxRightEdge,
  moveBoxTopEdge,
  moveBoxTopLeftCorner,
  moveBoxTopRightCorner,
} from "../box";
import { Offset } from "../offset";
import { Size } from "../style";

export interface PixelateInk {
  type: "pixelate";
  kind: "filter";
  size: Size;
  x: number;
  y: number;
  width: number;
  height: number;
}

export function createPreviewPixelateInk(size: Size, pos: Position): PixelateInk {
  return { type: "pixelate", kind: "filter", size: size, x: pos.x, y: pos.y, width: 0, height: 0 };
}

export function updatePreviewPixelateInk(ink: PixelateInk, pos: Position): PixelateInk {
  return {
    ...ink,
    width: pos.x - ink.x,
    height: pos.y - ink.y,
  };
}

export function finalizePreviewPixelateInk(ink: PixelateInk): PixelateInk {
  return ink;
}

export function boundPixelateInk(ink: PixelateInk): Box {
  return {
    x: ink.x,
    y: ink.y,
    width: ink.width,
    height: ink.height,
  };
}

export function movePixelateInk(ink: PixelateInk, offset: Offset): PixelateInk {
  return {
    ...ink,
    x: ink.x + offset.deltaX,
    y: ink.y + offset.deltaY,
  };
}

export function movePixelateInkTopLeftCorner(
  ink: PixelateInk,
  offset: Offset,
  keepRatio: boolean,
): PixelateInk {
  const box = moveBoxTopLeftCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function movePixelateInkTopRightCorner(
  ink: PixelateInk,
  offset: Offset,
  keepRatio: boolean,
): PixelateInk {
  const box = moveBoxTopRightCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function movePixelateInkBottomRightCorner(
  ink: PixelateInk,
  offset: Offset,
  keepRatio: boolean,
): PixelateInk {
  const box = moveBoxBottomRightCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function movePixelateInkBottomLeftCorner(
  ink: PixelateInk,
  offset: Offset,
  keepRatio: boolean,
): PixelateInk {
  const box = moveBoxBottomLeftCorner(ink, offset, keepRatio);
  return { ...ink, ...box };
}

export function movePixelateInkTopEdge(ink: PixelateInk, offset: Offset): PixelateInk {
  const box = moveBoxTopEdge(ink, offset);
  return { ...ink, ...box };
}

export function movePixelateInkRightEdge(ink: PixelateInk, offset: Offset): PixelateInk {
  const box = moveBoxRightEdge(ink, offset);
  return { ...ink, ...box };
}

export function movePixelateInkBottomEdge(ink: PixelateInk, offset: Offset): PixelateInk {
  const box = moveBoxBottomEdge(ink, offset);
  return { ...ink, ...box };
}

export function movePixelateInkLeftEdge(ink: PixelateInk, offset: Offset): PixelateInk {
  const box = moveBoxLeftEdge(ink, offset);
  return { ...ink, ...box };
}
