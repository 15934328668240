import { Position } from "../position";
import {
  Box,
  moveBoxBottomLeftCorner,
  moveBoxBottomRightCorner,
  moveBoxTopLeftCorner,
  moveBoxTopRightCorner,
} from "../box";
import { Offset } from "../offset";
import { Png } from "../png";

export interface ImageInk {
  type: "image";
  kind: "image";
  bitmap: ImageBitmap;
  density: number;
  x: number;
  y: number;
  width: number;
  height: number;
}

export function createPreviewImageInk(pos: Position, png: Png): ImageInk {
  const width = png.bitmap.width / png.density;
  const height = png.bitmap.height / png.density;
  return {
    type: "image",
    kind: "image",
    bitmap: png.bitmap,
    density: png.density,
    x: pos.x - width / 2,
    y: pos.y - height / 2,
    width: width,
    height: height,
  };
}

export function updatePreviewImageInk(ink: ImageInk, pos: Position): ImageInk {
  return {
    ...ink,
    x: pos.x - ink.width / 2,
    y: pos.y - ink.height / 2,
  };
}

export function finalizePreviewImageInk(ink: ImageInk): ImageInk {
  return ink;
}

export function boundImageInk(ink: ImageInk): Box {
  return {
    x: ink.x,
    y: ink.y,
    width: ink.width,
    height: ink.height,
  };
}

export function moveImageInk(ink: ImageInk, offset: Offset): ImageInk {
  return {
    ...ink,
    x: ink.x + offset.deltaX,
    y: ink.y + offset.deltaY,
  };
}

export function moveImageInkTopLeftCorner(ink: ImageInk, offset: Offset): ImageInk {
  const box = moveBoxTopLeftCorner(ink, offset, true);
  return { ...ink, ...box };
}

export function moveImageInkTopRightCorner(ink: ImageInk, offset: Offset): ImageInk {
  const box = moveBoxTopRightCorner(ink, offset, true);
  return { ...ink, ...box };
}

export function moveImageInkBottomRightCorner(ink: ImageInk, offset: Offset): ImageInk {
  const box = moveBoxBottomRightCorner(ink, offset, true);
  return { ...ink, ...box };
}

export function moveImageInkBottomLeftCorner(ink: ImageInk, offset: Offset): ImageInk {
  const box = moveBoxBottomLeftCorner(ink, offset, true);
  return { ...ink, ...box };
}
