import styles from "./RectInkEditor.module.scss";
import { FC, useCallback, useRef } from "react";
import { InkEditorProps } from "./InkEditor";
import { CornerHandle } from "../../views/CornerHandle";
import { Offset } from "../../model/offset";
import { HorizontalHandle } from "../../views/HorizontalHandle";
import { VerticalHandle } from "../../views/VerticalHandle";
import {
  BlurInk,
  moveBlurInkBottomEdge,
  moveBlurInkBottomLeftCorner,
  moveBlurInkBottomRightCorner,
  moveBlurInkLeftEdge,
  moveBlurInkRightEdge,
  moveBlurInkTopEdge,
  moveBlurInkTopLeftCorner,
  moveBlurInkTopRightCorner,
} from "../../model/tool/blur";

export const BlurInkEditor: FC<InkEditorProps<BlurInk>> = (props) => {
  const { onChange } = props;
  const draggingInkRef = useRef<BlurInk>();

  const handleDragStart = useCallback(() => {
    draggingInkRef.current = props.ink;
  }, [props.ink]);

  const handleTopEdgeDragMove = useCallback(
    (offset: Offset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveBlurInkTopEdge(draggingInkRef.current, offset));
    },
    [onChange],
  );

  const handleRightEdgeDragMove = useCallback(
    (offset: Offset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveBlurInkRightEdge(draggingInkRef.current, offset));
    },
    [onChange],
  );

  const handleBottomEdgeDragMove = useCallback(
    (offset: Offset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveBlurInkBottomEdge(draggingInkRef.current, offset));
    },
    [onChange],
  );

  const handleLeftEdgeDragMove = useCallback(
    (offset: Offset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveBlurInkLeftEdge(draggingInkRef.current, offset));
    },
    [onChange],
  );

  const handleTopLeftCornerDragMove = useCallback(
    (offset: Offset, event: MouseEvent) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveBlurInkTopLeftCorner(draggingInkRef.current, offset, event.shiftKey));
    },
    [onChange],
  );

  const handleTopRightCornerDragMove = useCallback(
    (offset: Offset, event: MouseEvent) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveBlurInkTopRightCorner(draggingInkRef.current, offset, event.shiftKey));
    },
    [onChange],
  );

  const handleBottomRightCornerDragMove = useCallback(
    (offset: Offset, event: MouseEvent) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveBlurInkBottomRightCorner(draggingInkRef.current, offset, event.shiftKey));
    },
    [onChange],
  );

  const handleBottomLeftCornerDragMove = useCallback(
    (offset: Offset, event: MouseEvent) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveBlurInkBottomLeftCorner(draggingInkRef.current, offset, event.shiftKey));
    },
    [onChange],
  );

  const handleDragEnd = useCallback(() => {
    draggingInkRef.current = undefined;
  }, []);

  return (
    <div className={styles.container} onDoubleClick={() => console.log("double click")}>
      <div style={{ top: 0, left: 0 }} className={styles.horizontalHandleWrapper}>
        <HorizontalHandle
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleTopEdgeDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: 0, left: "100%" }} className={styles.verticalHandleWrapper}>
        <VerticalHandle
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleRightEdgeDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: "100%", left: 0 }} className={styles.horizontalHandleWrapper}>
        <HorizontalHandle
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleBottomEdgeDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: 0, left: 0 }} className={styles.verticalHandleWrapper}>
        <VerticalHandle
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleLeftEdgeDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: 0, left: 0 }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nwse"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleTopLeftCornerDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: 0, left: "100%" }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nesw"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleTopRightCornerDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: "100%", left: "100%" }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nwse"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleBottomRightCornerDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
      <div style={{ top: "100%", left: 0 }} className={styles.cornerHandleWrapper}>
        <CornerHandle
          orientation="nesw"
          zoom={props.zoom}
          onDragStart={handleDragStart}
          onDragMove={handleBottomLeftCornerDragMove}
          onDragEnd={handleDragEnd}
        />
      </div>
    </div>
  );
};
