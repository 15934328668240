import {
  boundRectInk,
  createPreviewRectInk,
  finalizePreviewRectInk,
  moveRectInk,
  RectInk,
  updatePreviewRectInk,
} from "./rect";
import { Position } from "../position";
import { assertNever } from "../../lib/assert-never";
import {
  boundImageInk,
  finalizePreviewImageInk,
  ImageInk,
  moveImageInk,
  updatePreviewImageInk,
} from "./image";
import {
  BlurInk,
  boundBlurInk,
  createPreviewBlurInk,
  finalizePreviewBlurInk,
  moveBlurInk,
  updatePreviewBlurInk,
} from "./blur";
import {
  boundTextInk,
  createPreviewTextInk,
  finalizePreviewTextInk,
  moveTextInk,
  TextInk,
  updatePreviewTextInk,
} from "./text";
import {
  boundPixelateInk,
  createPreviewPixelateInk,
  finalizePreviewPixelateInk,
  movePixelateInk,
  PixelateInk,
  updatePreviewPixelateInk,
} from "./pixelate";
import { Box } from "../box";
import { Offset } from "../offset";
import {
  boundPenInk,
  createPreviewPenInk,
  finalizePreviewPenInk,
  movePenInk,
  PenInk,
  updatePreviewPenInk,
} from "./pen";
import { Color, Size, Style } from "../style";
import {
  ArrowInk,
  boundArrowInk,
  createPreviewArrowInk,
  finalizePreviewArrowInk,
  moveArrowInk,
  updatePreviewArrowInk,
} from "./arrow";
import {
  boundNumberInk,
  createPreviewNumberInk,
  finalizePreviewNumberInk,
  moveNumberInk,
  NumberInk,
  updatePreviewNumberInk,
} from "./number";
import {
  boundRedactInk,
  createPreviewRedactInk,
  finalizePreviewRedactInk,
  moveRedactInk,
  RedactInk,
  updatePreviewRedactInk,
} from "./redact";

export type Tool = "rect" | "blur" | "text" | "pixelate" | "pen" | "arrow" | "number" | "redact";

export type Ink = ImageInk | DrawingInk | FilterInk;

export type DrawingInk = RectInk | TextInk | PenInk | ArrowInk | NumberInk;

export type FilterInk = BlurInk | PixelateInk | RedactInk;

export function changeInkColor(ink: Ink, color: Color): Ink {
  if (ink.kind === "drawing") {
    return { ...ink, style: { ...ink.style, color: color } } satisfies DrawingInk;
  }
  return ink;
}

export function changeInkSize(ink: Ink, size: Size): Ink {
  if (ink.kind === "drawing") {
    return { ...ink, style: { ...ink.style, size: size } } satisfies DrawingInk;
  }
  if (ink.kind === "filter") {
    return { ...ink, size: size } satisfies FilterInk;
  }
  return ink;
}

export function createPreviewInk(tool: Tool, style: Style, pos: Position): Ink {
  if (tool === "rect") {
    return createPreviewRectInk(style, pos);
  }
  if (tool === "blur") {
    return createPreviewBlurInk(style.size, pos);
  }
  if (tool === "text") {
    return createPreviewTextInk(style, pos);
  }
  if (tool === "pixelate") {
    return createPreviewPixelateInk(style.size, pos);
  }
  if (tool === "pen") {
    return createPreviewPenInk(style, pos);
  }
  if (tool === "arrow") {
    return createPreviewArrowInk(style, pos);
  }
  if (tool === "number") {
    return createPreviewNumberInk(style, pos);
  }
  if (tool === "redact") {
    return createPreviewRedactInk(style.size, pos);
  }
  assertNever(tool);
}

export function updatePreviewInk(ink: Ink, pos: Position): Ink {
  if (ink.type === "rect") {
    return updatePreviewRectInk(ink, pos);
  }
  if (ink.type === "image") {
    return updatePreviewImageInk(ink, pos);
  }
  if (ink.type === "blur") {
    return updatePreviewBlurInk(ink, pos);
  }
  if (ink.type === "text") {
    return updatePreviewTextInk(ink, pos);
  }
  if (ink.type === "pixelate") {
    return updatePreviewPixelateInk(ink, pos);
  }
  if (ink.type === "pen") {
    return updatePreviewPenInk(ink, pos);
  }
  if (ink.type === "arrow") {
    return updatePreviewArrowInk(ink, pos);
  }
  if (ink.type === "number") {
    return updatePreviewNumberInk(ink, pos);
  }
  if (ink.type === "redact") {
    return updatePreviewRedactInk(ink, pos);
  }
  assertNever(ink);
}

export function finalizePreviewInk(ink: Ink): Ink | undefined {
  if (ink.type === "rect") {
    return finalizePreviewRectInk(ink);
  }
  if (ink.type === "image") {
    return finalizePreviewImageInk(ink);
  }
  if (ink.type === "blur") {
    return finalizePreviewBlurInk(ink);
  }
  if (ink.type === "text") {
    return finalizePreviewTextInk(ink);
  }
  if (ink.type === "pixelate") {
    return finalizePreviewPixelateInk(ink);
  }
  if (ink.type === "pen") {
    return finalizePreviewPenInk(ink);
  }
  if (ink.type === "arrow") {
    return finalizePreviewArrowInk(ink);
  }
  if (ink.type === "number") {
    return finalizePreviewNumberInk(ink);
  }
  if (ink.type === "redact") {
    return finalizePreviewRedactInk(ink);
  }
  assertNever(ink);
}

export function boundInk(ink: Ink): Box {
  if (ink.type === "rect") {
    return boundRectInk(ink);
  }
  if (ink.type === "image") {
    return boundImageInk(ink);
  }
  if (ink.type === "blur") {
    return boundBlurInk(ink);
  }
  if (ink.type === "text") {
    return boundTextInk(ink);
  }
  if (ink.type === "pixelate") {
    return boundPixelateInk(ink);
  }
  if (ink.type === "pen") {
    return boundPenInk(ink);
  }
  if (ink.type === "arrow") {
    return boundArrowInk(ink);
  }
  if (ink.type === "number") {
    return boundNumberInk(ink);
  }
  if (ink.type === "redact") {
    return boundRedactInk(ink);
  }
  assertNever(ink);
}

export function moveInk(ink: Ink, offset: Offset): Ink {
  if (ink.type === "rect") {
    return moveRectInk(ink, offset);
  }
  if (ink.type === "image") {
    return moveImageInk(ink, offset);
  }
  if (ink.type === "blur") {
    return moveBlurInk(ink, offset);
  }
  if (ink.type === "text") {
    return moveTextInk(ink, offset);
  }
  if (ink.type === "pixelate") {
    return movePixelateInk(ink, offset);
  }
  if (ink.type === "pen") {
    return movePenInk(ink, offset);
  }
  if (ink.type === "arrow") {
    return moveArrowInk(ink, offset);
  }
  if (ink.type === "number") {
    return moveNumberInk(ink, offset);
  }
  if (ink.type === "redact") {
    return moveRedactInk(ink, offset);
  }
  assertNever(ink);
}
