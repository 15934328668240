import { FC, MouseEvent as ReactMouseEvent, useCallback, useEffect, useRef, useState } from "react";
import styles from "./Editor.module.scss";
import { Item } from "../model/item";
import { Offset } from "../model/offset";
import { fromViewportPosition, Position, ViewportPosition } from "../model/position";
import {
  boundInk,
  createPreviewInk,
  finalizePreviewInk,
  Tool,
  updatePreviewInk,
} from "../model/tool";
import {
  fromViewportBox,
  isBoxTouching,
  normalizeBox,
  ViewportBox,
  viewportBoxOf,
} from "../model/box";
import { Style } from "../model/style";
import { trackEvent } from "../analytics";
import { TextInk } from "../model/tool/text";
import { TextInkEditor } from "./InkEditor/TextInkEditor";
import { TextInkEditDialog } from "./TextInkEditDialog";

interface Props {
  tool: Tool | undefined;
  activeItemIds: string[];
  onDraw: (pos: ViewportPosition) => void;
  onDrawEnd: () => void;
  onSelectArea: (area: ViewportBox, multiple: boolean) => void;
}

export const Editor: FC<Props> = (props) => {
  const { onDraw, onDrawEnd, onSelectArea } = props;

  const ref = useRef<HTMLDivElement>(null);
  const drawingRef = useRef<boolean>(false);
  const { selectionBox, startSelection, updateSelection, endSelection } = useSelectionBox();

  const handleMouseDown = useCallback(
    async (e: ReactMouseEvent) => {
      if (e.button !== 0) {
        return;
      }
      if (ref.current === null) {
        return;
      }
      const offset = elementOffsetOf(ref.current);
      const viewportPos = {
        viewportX: e.pageX - offset.deltaX,
        viewportY: e.pageY - offset.deltaY,
      };
      if (props.tool === undefined) {
        startSelection(viewportPos);
      } else {
        drawingRef.current = true;
        onDraw(viewportPos);
        await trackEvent({ name: "draw", tool: props.tool });
      }
    },
    [props.tool, onDraw],
  );

  const lastMovedAtRef = useRef<number>(0);
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const now = Date.now();
      if (now - lastMovedAtRef.current < 20) {
        return;
      }
      lastMovedAtRef.current = now;

      requestAnimationFrame(() => {
        if (ref.current === null) {
          return;
        }
        const offset = elementOffsetOf(ref.current);
        const viewportPos = {
          viewportX: e.pageX - offset.deltaX,
          viewportY: e.pageY - offset.deltaY,
        };
        if (drawingRef.current) {
          onDraw(viewportPos);
        } else {
          updateSelection(viewportPos);
        }
      });
    };
    window.addEventListener("mousemove", handler);
    return () => {
      window.removeEventListener("mousemove", handler);
    };
  }, [onDraw, updateSelection]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      // Preview Finalization
      if (drawingRef.current) {
        drawingRef.current = false;
        onDrawEnd();
      }

      // Selection
      if (selectionBox !== undefined) {
        onSelectArea(selectionBox, e.shiftKey);
        endSelection();
      }
    };
    window.addEventListener("mouseup", handler);
    return () => {
      window.removeEventListener("mouseup", handler);
    };
  }, [selectionBox, endSelection, onDrawEnd]);

  return (
    <div
      ref={ref}
      data-tool={props.tool}
      className={styles.container}
      onMouseDown={handleMouseDown}
    >
      {selectionBox !== undefined && (
        <div
          style={{
            top: selectionBox.viewportY,
            left: selectionBox.viewportX,
            width: selectionBox.viewportWidth,
            height: selectionBox.viewportHeight,
          }}
          className={styles.previewBox}
        />
      )}
    </div>
  );
};

function useSelectionBox(): {
  selectionBox: ViewportBox | undefined;
  startSelection: (pos: ViewportPosition) => void;
  updateSelection: (pos: ViewportPosition) => void;
  endSelection: () => void;
} {
  const selectionStartPosRef = useRef<ViewportPosition>();
  const [selectionBox, setSelectionBox] = useState<ViewportBox>();

  const start = useCallback((pos: ViewportPosition) => {
    selectionStartPosRef.current = pos;
    setSelectionBox({ ...pos, viewportWidth: 0, viewportHeight: 0 });
  }, []);

  const update = useCallback((pos: ViewportPosition) => {
    if (selectionStartPosRef.current !== undefined) {
      setSelectionBox(viewportBoxOf(selectionStartPosRef.current, pos));
    }
  }, []);

  const end = useCallback(() => {
    selectionStartPosRef.current = undefined;
    setSelectionBox(undefined);
  }, []);

  return {
    selectionBox,
    startSelection: start,
    updateSelection: update,
    endSelection: end,
  };
}

function elementOffsetOf(element: HTMLElement): Offset {
  const boundingRect = element.getBoundingClientRect();
  return {
    deltaX: boundingRect.x + window.scrollX,
    deltaY: boundingRect.y + window.scrollY,
  };
}

function toggleItemId(ids: string[], id: string): string[] {
  return ids.includes(id) ? ids.filter((it) => it !== id) : [...ids, id];
}
