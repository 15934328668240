import { IconProps } from "./props";
import { FC } from "react";

export const UndoIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <mask
        id="mask0_289_202"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_289_202)">
        <path
          d="M7.202 18.5V17H14.3788C15.4224 17 16.3205 16.6554 17.073 15.9663C17.8257 15.2773 18.202 14.4263 18.202 13.4135C18.202 12.4007 17.8257 11.5512 17.073 10.8652C16.3205 10.1794 15.4224 9.8365 14.3788 9.8365H7.35775L10.1402 12.6193L9.0865 13.673L4.5 9.0865L9.0865 4.5L10.1402 5.55375L7.35775 8.3365H14.3788C15.8416 8.3365 17.0945 8.82467 18.1375 9.801C19.1805 10.7773 19.702 11.9815 19.702 13.4135C19.702 14.8455 19.1805 16.0513 18.1375 17.0308C17.0945 18.0103 15.8416 18.5 14.3788 18.5H7.202Z"
          fill="#a3a3a3"
        />
      </g>
    </svg>
  );
};
