import {
  ChangeEvent,
  FC,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useState,
  WheelEvent,
} from "react";
import { TextInk } from "../model/tool/text";
import { rformat, useTexts } from "../helpers/texts";
import * as Dialog from "@radix-ui/react-dialog";
import styles from "./InkEditor/TextInkEditor.module.scss";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { AlignLeftIcon } from "../views/icons/AlignLeftIcon";
import { AlignCenterIcon } from "../views/icons/AlignCenterIcon";
import { AlignRightIcon } from "../views/icons/AlignRightIcon";

const textMap = {
  "press %s to insert a new line.": {
    en: "Press %s to insert a new line.",
    ja: "%s で改行を挿入できます。",
  },
  "type here...": {
    en: "Type here...",
    ja: "ここに入力...",
  },
  ok: {
    en: "OK",
    ja: "OK",
  },
  cancel: {
    en: "Cancel",
    ja: "キャンセル",
  },
};

export const TextInkEditDialog: FC<{
  initialValue: TextInk;
  onSubmit: (ink: TextInk) => void;
  onCancel: () => void;
}> = (props) => {
  const { onSubmit, onCancel } = props;
  const texts = useTexts(textMap);
  const [text, setText] = useState(props.initialValue.text);
  const [align, setAlign] = useState<"left" | "center" | "right">(props.initialValue.align);

  const submit = useCallback(() => {
    onSubmit({ ...props.initialValue, text: text, align: align });
  }, [onSubmit, props.initialValue, text, align]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        onCancel();
      }
    },
    [onCancel],
  );

  const handleMouseDown = useCallback((e: MouseEvent) => {
    // ダイアログ内のマウスイベントで ItemEditor が移動しないように伝播を止める
    e.stopPropagation();
  }, []);

  const handleWheel = useCallback((e: WheelEvent) => {
    // ダイアログ内のホイールイベントでビューポートが操作されないように伝播を止める
    // ItemEditor を Viewport の外側に出すとアイテム上でも Viewport の操作ができなくなるのでそれはできない
    e.stopPropagation();
  }, []);

  const handleEditTextChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // ダイアログ内でグローバルなショートカットが効かないように伝播を止める
      e.stopPropagation();

      if (!e.nativeEvent.isComposing) {
        if (e.key === "Escape") {
          onCancel();
        }
        if (e.key === "Enter" && !e.shiftKey) {
          submit();
        }
      }
    },
    [onCancel, submit],
  );

  const handleAlignValueChange = useCallback((value: string) => {
    if (value === "left" || value === "center" || value === "right") {
      setAlign(value);
    }
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      submit();
    },
    [submit],
  );

  const handleCancelClick = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <Dialog.Root open={true} onOpenChange={handleOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content
          className={styles.content}
          onMouseDown={handleMouseDown}
          onWheel={handleWheel}
        >
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.textareaWrapper}>
              <p className={styles.newLineHint}>
                {rformat(
                  texts["press %s to insert a new line."],
                  <span className={styles.shortcut}>⇧↩</span>,
                )}
              </p>
              <textarea
                value={text}
                placeholder={texts["type here..."]}
                style={{ textAlign: align }}
                className={styles.textarea}
                onChange={handleEditTextChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className={styles.footer}>
              <ToggleGroup.Root
                type="single"
                value={align}
                className={styles.alignmentButtons}
                onValueChange={handleAlignValueChange}
              >
                <ToggleGroup.Item value="left" className={styles.alignmentButton}>
                  <AlignLeftIcon />
                </ToggleGroup.Item>
                <ToggleGroup.Item value="center" className={styles.alignmentButton}>
                  <AlignCenterIcon />
                </ToggleGroup.Item>
                <ToggleGroup.Item value="right" className={styles.alignmentButton}>
                  <AlignRightIcon />
                </ToggleGroup.Item>
              </ToggleGroup.Root>
              <div className={styles.buttons}>
                <button className={styles.button} onClick={handleCancelClick}>
                  {texts.cancel}
                </button>
                <button className={styles.button} type="submit">
                  {texts.ok}
                </button>
              </div>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
