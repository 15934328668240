import { FC } from "react";
import { IconProps } from "./props";

export const RectToolIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <mask
        id="mask0_289_149"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_289_149)">
        <path
          d="M5.88626 19.3386C5.41376 19.3386 5.0116 19.1726 4.67976 18.8406C4.34776 18.5088 4.18176 18.1059 4.18176 17.6319V6.36837C4.18176 5.89437 4.34776 5.49145 4.67976 5.15962C5.0116 4.82762 5.41376 4.66162 5.88626 4.66162H19.1138C19.5863 4.66162 19.9884 4.82762 20.3203 5.15962C20.6523 5.49145 20.8183 5.89437 20.8183 6.36837V17.6319C20.8183 18.1059 20.6523 18.5088 20.3203 18.8406C19.9884 19.1726 19.5863 19.3386 19.1138 19.3386H5.88626ZM5.88851 17.9396H19.1115C19.1885 17.9396 19.259 17.9075 19.323 17.8434C19.3872 17.7792 19.4193 17.7087 19.4193 17.6319V6.36837C19.4193 6.29154 19.3872 6.22104 19.323 6.15687C19.259 6.0927 19.1885 6.06062 19.1115 6.06062H5.88851C5.81151 6.06062 5.74101 6.0927 5.67701 6.15687C5.61285 6.22104 5.58076 6.29154 5.58076 6.36837V17.6319C5.58076 17.7087 5.61285 17.7792 5.67701 17.8434C5.74101 17.9075 5.81151 17.9396 5.88851 17.9396Z"
          fill="#A3A3A3"
        />
      </g>
    </svg>
  );
};
