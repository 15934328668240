export type Color = "pink" | "red" | "orange" | "green" | "cyan" | "purple" | "white" | "black";

export type Size = "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl";

export interface Style {
  color: Color;
  size: Size;
}

const ratio = {
  xxs: 1 / 1.4 ** 3,
  xs: 1 / 1.4 ** 2,
  s: 1 / 1.4 ** 1,
  m: 1,
  l: 1.4 ** 1,
  xl: 1.4 ** 2,
  xxl: 1.4 ** 3,
};

export function applySize(x: number, size: Size): number {
  return x * ratio[size];
}
