import { IconProps } from "./props";
import { FC } from "react";

export const XsSizeIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M20.0727 18.1878C19.294 18.1878 18.5687 18.0543 17.8967 17.7871C17.2354 17.52 16.6647 17.0859 16.1847 16.4849C16.0354 16.2734 15.9767 16.0786 16.0087 15.9005C16.0514 15.7113 16.174 15.5555 16.3767 15.433C16.5687 15.3106 16.7447 15.2717 16.9047 15.3162C17.0754 15.3496 17.246 15.4609 17.4167 15.6501C17.7367 15.9951 18.1207 16.2623 18.5687 16.4515C19.0167 16.6296 19.534 16.7186 20.1207 16.7186C20.8674 16.7186 21.4487 16.5628 21.8647 16.2511C22.2807 15.9395 22.4887 15.4831 22.4887 14.8821C22.4887 14.5259 22.414 14.2198 22.2647 13.9638C22.1154 13.6967 21.8167 13.4574 21.3687 13.2459C20.9207 13.0344 20.2434 12.8341 19.3367 12.6449C17.278 12.1774 16.2487 11.081 16.2487 9.35583C16.2487 8.688 16.4034 8.10365 16.7127 7.60278C17.022 7.10191 17.454 6.71235 18.0087 6.43409C18.5634 6.1447 19.2087 6 19.9447 6C20.67 6 21.3207 6.13357 21.8967 6.4007C22.4727 6.6567 22.9794 6.99617 23.4167 7.41913C23.5767 7.59722 23.646 7.78087 23.6247 7.97009C23.6034 8.1593 23.502 8.33183 23.3207 8.48765C23.1287 8.64348 22.9474 8.71026 22.7767 8.688C22.6167 8.66574 22.446 8.56557 22.2647 8.38748C21.9447 8.10922 21.598 7.89217 21.2247 7.73635C20.8514 7.56939 20.4194 7.48591 19.9287 7.48591C19.31 7.48591 18.7927 7.64174 18.3767 7.95339C17.9714 8.25391 17.7687 8.69913 17.7687 9.28904C17.7687 9.6007 17.8327 9.88452 17.9607 10.1405C18.0994 10.3854 18.35 10.6024 18.7127 10.7917C19.0754 10.9809 19.6034 11.1534 20.2967 11.3092C21.6194 11.5986 22.5687 12.0327 23.1447 12.6115C23.7314 13.1903 24.0247 13.9416 24.0247 14.8654C24.0247 15.5777 23.854 16.1843 23.5127 16.6852C23.182 17.175 22.718 17.5478 22.1207 17.8038C21.534 18.0598 20.8514 18.1878 20.0727 18.1878Z"
        fill="#ffffff"
      />
      <path
        d="M6.67613 17.9709C6.3028 17.9709 6.0788 17.8707 6.00413 17.6704C5.92946 17.47 5.9668 17.2474 6.11613 17.0025L9.26813 11.8436L6.43613 7.25228C6.2228 6.9295 6.16946 6.6902 6.27613 6.53437C6.3828 6.36741 6.61746 6.28394 6.98013 6.28394C7.38546 6.28394 7.68946 6.46202 7.89213 6.8182L10.0681 10.4578L12.2281 6.8182C12.4308 6.46202 12.7348 6.28394 13.1401 6.28394C13.8548 6.28394 14.0361 6.60672 13.6841 7.25228L10.8681 11.8436L14.0201 17.0025C14.1908 17.2808 14.2281 17.5145 14.1321 17.7038C14.0468 17.8818 13.8175 17.9709 13.4441 17.9709C13.0388 17.9709 12.7348 17.7928 12.5321 17.4366L10.0681 13.246L7.58813 17.4366C7.38546 17.7928 7.08146 17.9709 6.67613 17.9709Z"
        fill="#ffffff"
      />
    </svg>
  );
};
