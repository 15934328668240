.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 60px 80px;
  color: var(--color-gray-9);
}

.title {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  color: var(--color-gray-10);
}

.paragraphs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.paragraph {
  margin: 0;
}

.shortcut {
  padding: 0 2px;
  font: var(--font-shortcut);
  color: var(--color-gray-10);
}

.selectImage {
  margin: 0;
}

.selectImageLink {
  color: var(--color-gray-10);
}

.selectInput {
  display: none;
}

.corner {
  position: absolute;
  width: 25px;
  height: 25px;
  border: 3px solid var(--color-gray-9);
  opacity: 0.4;
  pointer-events: none;
}

.topLeft {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
  border-top-left-radius: 16px;
}

.topRight {
  top: 0;
  right: 0;
  border-bottom: none;
  border-left: none;
  border-top-right-radius: 16px;
}

.bottomRight {
  bottom: 0;
  right: 0;
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 16px;
}

.bottomLeft {
  bottom: 0;
  left: 0;
  border-top: none;
  border-right: none;
  border-bottom-left-radius: 16px;
}
