import { FC, useCallback, MouseEvent, useRef, ChangeEvent } from "react";
import styles from "./Welcome.module.scss";
import { cx } from "../lib/cx";
import { rformat, useTexts } from "../helpers/texts";

const textMap = {
  "welcome to snapink": {
    en: "Welcome to SnapInk",
    ja: "SnapInk へようこそ",
  },
  "press %s to paste an image from your clipboard.": {
    en: "Press %s to paste an image from your clipboard.",
    ja: "%s を押すと、クリップボードから画像を貼り付けます。",
  },
  "press %s to export your work as a PNG to your clipboard.": {
    en: "Press %s to export your work as a PNG to your clipboard.",
    ja: "%s を押すと、作業内容を PNG でクリップボードに書き出します。",
  },
  "you can also %s to start drawing.": {
    en: "You can also %s to start drawing.",
    ja: "%sして描画を開始することもできます。",
  },
  "select an image": {
    en: "select an image",
    ja: "画像を選択",
  },
};

interface Props {
  onImageSelect: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Welcome: FC<Props> = (props) => {
  const texts = useTexts(textMap);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelectImageClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    inputRef.current?.click();
  }, []);

  return (
    <div className={styles.container}>
      <div className={cx(styles.corner, styles.topLeft)} />
      <div className={cx(styles.corner, styles.topRight)} />
      <div className={cx(styles.corner, styles.bottomRight)} />
      <div className={cx(styles.corner, styles.bottomLeft)} />
      <div className={styles.title}>{texts["welcome to snapink"]}</div>
      <div className={styles.paragraphs}>
        <p className={styles.paragraph}>
          {rformat(
            texts["press %s to paste an image from your clipboard."],
            <span className={styles.shortcut}>⌘V</span>,
          )}
        </p>
        <p className={styles.paragraph}>
          {rformat(
            texts["press %s to export your work as a PNG to your clipboard."],
            <span className={styles.shortcut}>⇧⌘C</span>,
          )}
        </p>
      </div>
      <p className={styles.selectImage}>
        {rformat(
          texts["you can also %s to start drawing."],
          <a href="#" className={styles.selectImageLink} onClick={handleSelectImageClick}>
            {texts["select an image"]}
          </a>,
        )}
      </p>
      <input
        ref={inputRef}
        type="file"
        accept="image/png"
        className={styles.selectInput}
        onChange={props.onImageSelect}
      />
    </div>
  );
};
