import { FC, useCallback, useMemo, useState } from "react";
import styles from "./PenInkEditor.module.scss";
import { InkEditorProps } from "./InkEditor";
import { boundPenInk, PenInk } from "../../model/tool/pen";
import { normalizeBox, toViewportBox } from "../../model/box";

export const PenInkEditor: FC<InkEditorProps<PenInk>> = (props) => {
  const [editMode, setEditMode] = useState(false);

  const boundingBox = useMemo(() => {
    return normalizeBox(boundPenInk(props.ink));
  }, [props.ink, props.pan, props.zoom]);

  const handleDoubleClick = useCallback(() => {
    setEditMode(true);
  }, []);

  return (
    <div className={styles.container} onDoubleClick={handleDoubleClick}>
      {editMode &&
        props.ink.points.map((point, i) => (
          <div
            key={i}
            style={{
              top: (point.y - boundingBox.y) * props.zoom,
              left: (point.x - boundingBox.x) * props.zoom,
            }}
            className={styles.pointHandleWrapper}
          >
            <PointHandle />
          </div>
        ))}
    </div>
  );
};

const PointHandle: FC = () => {
  return <div className={styles.pointHandle} />;
};
