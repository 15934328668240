import { FC, useCallback, useMemo, useRef } from "react";
import styles from "./ArrowInkEditor.module.scss";
import { ArrowInk, boundArrowInk, moveArrowInkFrom, moveArrowInkTo } from "../../model/tool/arrow";
import { InkEditorProps } from "./InkEditor";
import { fromViewportOffset, Offset, ViewportOffset } from "../../model/offset";
import { useDrag } from "../../lib/drag";

export const ArrowInkEditor: FC<InkEditorProps<ArrowInk>> = (props) => {
  const { onChange } = props;
  const draggingInkRef = useRef<ArrowInk>();

  const boundingBox = useMemo(() => {
    return boundArrowInk(props.ink);
  }, [props.ink, props.pan, props.zoom]);

  const handleDragStart = useCallback(() => {
    draggingInkRef.current = props.ink;
  }, [props.ink]);

  const handleDragEnd = useCallback(() => {
    draggingInkRef.current = undefined;
  }, []);

  const handleFromDragMove = useCallback(
    (offset: ViewportOffset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveArrowInkFrom(draggingInkRef.current, fromViewportOffset(offset, props.zoom)));
    },
    [onChange, props.zoom],
  );

  const handleToDragMove = useCallback(
    (offset: ViewportOffset) => {
      if (draggingInkRef.current === undefined) {
        return;
      }
      onChange(moveArrowInkTo(draggingInkRef.current, fromViewportOffset(offset, props.zoom)));
    },
    [onChange, props.zoom],
  );

  return (
    <div className={styles.container}>
      <PointHandle
        top={(props.ink.from.y - boundingBox.y) * props.zoom}
        left={(props.ink.from.x - boundingBox.x) * props.zoom}
        onDragStart={handleDragStart}
        onDragMove={handleFromDragMove}
        onDragEnd={handleDragEnd}
      />
      <PointHandle
        top={(props.ink.to.y - boundingBox.y) * props.zoom}
        left={(props.ink.to.x - boundingBox.x) * props.zoom}
        onDragStart={handleDragStart}
        onDragMove={handleToDragMove}
        onDragEnd={handleDragEnd}
      />
    </div>
  );
};

const PointHandle: FC<{
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  onDragStart?: () => void;
  onDragMove?: (offset: ViewportOffset, event: MouseEvent) => void;
  onDragEnd?: () => void;
}> = (props) => {
  const { onDragMove } = props;

  const handleDragMove = useCallback(
    (offset: Offset, event: MouseEvent) => {
      onDragMove?.({ viewportDeltaX: offset.deltaX, viewportDeltaY: offset.deltaY }, event);
    },
    [onDragMove],
  );

  const handleMouseDown = useDrag({
    onDragStart: props.onDragStart,
    onDragMove: handleDragMove,
    onDragEnd: props.onDragEnd,
  });

  return (
    <div
      style={{
        top: props.top,
        right: props.right,
        bottom: props.bottom,
        left: props.left,
      }}
      className={styles.pointHandle}
      onMouseDown={handleMouseDown}
    />
  );
};
