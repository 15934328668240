import * as Toast from "@radix-ui/react-toast";
import styles from "./toaster.module.scss";
import { createContext, FC, ReactNode, useContext, useMemo, useState } from "react";

interface ToastData {
  id: string;
  title?: string;
  description?: string;
  duration?: number;
  action?: {
    // https://www.radix-ui.com/primitives/docs/components/toast#alternative-action
    altText: string;
    label: string;
    callback: () => void;
  };
  closable?: boolean;
}

interface Context {
  show: (message: string, duration?: number) => void;
}

const context = createContext<Context>({
  show: () => {
    throw new Error("Toaster is not provided");
  },
});

export function useToaster() {
  return useContext(context);
}

interface Props {
  children: ReactNode;
}

export const Toaster: FC<Props> = (props) => {
  const [toasts, setToasts] = useState<ToastData[]>([]);

  const contextValue: Context = useMemo(() => {
    return {
      show: (message, duration) => {
        setToasts((prev) => [
          {
            id: crypto.randomUUID(),
            description: message,
            duration: duration,
          },
          ...prev,
        ]);
      },
    };
  }, []);

  return (
    <context.Provider value={contextValue}>
      <Toast.Provider duration={3000}>
        {props.children}
        {toasts.map((toast) => (
          <Toast.Root
            key={toast.id}
            duration={toast.duration}
            className={styles.toast}
          >
            {toast.title !== undefined && (
              <Toast.Title>{toast.title}</Toast.Title>
            )}
            {toast.description !== undefined && (
              <Toast.Description>{toast.description}</Toast.Description>
            )}
            {toast.action !== undefined && (
              <Toast.Action
                altText={toast.action.altText}
                onClick={toast.action.callback}
              >
                {toast.action.label}
              </Toast.Action>
            )}
            {toast.closable && (
              <Toast.Close className={styles.closeButton}>
                <CloseIcon />
              </Toast.Close>
            )}
          </Toast.Root>
        ))}
        <Toast.Viewport className={styles.viewport} />
      </Toast.Provider>
    </context.Provider>
  );
};

const CloseIcon: FC = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_62_32"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <rect x="0.591064" y="0.367188" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_62_32)">
        <path
          d="M6.99106 19.3672L5.59106 17.9672L11.1911 12.3672L5.59106 6.76719L6.99106 5.36719L12.5911 10.9672L18.1911 5.36719L19.5911 6.76719L13.9911 12.3672L19.5911 17.9672L18.1911 19.3672L12.5911 13.7672L6.99106 19.3672Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
