import { FC } from "react";
import * as Select from "@radix-ui/react-select";
import styles from "./SizeSelect.module.scss";
import { XxsSizeIcon } from "../views/icons/XxsSizeIcon";
import { XsSizeIcon } from "../views/icons/XsSizeIcon";
import { SSizeIcon } from "../views/icons/SSizeIcon";
import { MSizeIcon } from "../views/icons/MSizeIcon";
import { LSizeIcon } from "../views/icons/LSizeIcon";
import { XlSizeIcon } from "../views/icons/XlSizeIcon";
import { XxlSizeIcon } from "../views/icons/XxlSizeIcon";
import { assertNever } from "../lib/assert-never";
import { Color, Size } from "../model/style";

interface Props {
  size: Size;
  color: Color;
  disabled?: boolean;
  onChange: (size: Size) => void;
}

export const SizeSelect: FC<Props> = (props) => {
  return (
    <Select.Root value={props.size} disabled={props.disabled} onValueChange={props.onChange}>
      <Select.Trigger className={styles.trigger}>
        {props.size === "xxs" ? (
          <XxsSizeIcon />
        ) : props.size === "xs" ? (
          <XsSizeIcon />
        ) : props.size === "s" ? (
          <SSizeIcon />
        ) : props.size === "m" ? (
          <MSizeIcon />
        ) : props.size === "l" ? (
          <LSizeIcon />
        ) : props.size === "xl" ? (
          <XlSizeIcon />
        ) : props.size === "xxl" ? (
          <XxlSizeIcon />
        ) : (
          assertNever(props.size)
        )}
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          position="popper"
          side="bottom"
          align="center"
          sideOffset={16}
          className={styles.content}
        >
          <Select.Arrow />
          <Select.Viewport className={styles.items}>
            <Select.Item value="xxs" className={styles.item}>
              <div
                data-size="xxs"
                style={{ backgroundColor: `var(--color-${props.color})` }}
                className={styles.circle}
              />
              <XxsSizeIcon />
            </Select.Item>
            <Select.Item value="xs" className={styles.item}>
              <div
                data-size="xs"
                style={{ backgroundColor: `var(--color-${props.color})` }}
                className={styles.circle}
              />
              <XsSizeIcon />
            </Select.Item>
            <Select.Item value="s" className={styles.item}>
              <div
                data-size="s"
                style={{ backgroundColor: `var(--color-${props.color})` }}
                className={styles.circle}
              />
              <SSizeIcon />
            </Select.Item>
            <Select.Item value="m" className={styles.item}>
              <div
                data-size="m"
                style={{ backgroundColor: `var(--color-${props.color})` }}
                className={styles.circle}
              />
              <MSizeIcon />
            </Select.Item>
            <Select.Item value="l" className={styles.item}>
              <div
                data-size="l"
                style={{ backgroundColor: `var(--color-${props.color})` }}
                className={styles.circle}
              />
              <LSizeIcon />
            </Select.Item>
            <Select.Item value="xl" className={styles.item}>
              <div
                data-size="xl"
                style={{ backgroundColor: `var(--color-${props.color})` }}
                className={styles.circle}
              />
              <XlSizeIcon />
            </Select.Item>
            <Select.Item value="xxl" className={styles.item}>
              <div
                data-size="xxl"
                style={{ backgroundColor: `var(--color-${props.color})` }}
                className={styles.circle}
              />
              <XxlSizeIcon />
            </Select.Item>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
