import { Offset } from "./offset";

import { Position, ViewportPosition } from "./position";

export interface Box {
  x: number;
  y: number;
  width: number;
  height: number;
}

export function boxOf(pos1: Position, pos2: Position): Box {
  return {
    x: Math.min(pos1.x, pos2.x),
    y: Math.min(pos1.y, pos2.y),
    width: Math.abs(pos2.x - pos1.x),
    height: Math.abs(pos2.y - pos1.y),
  };
}

export function normalizeBox(box: Box): Box {
  return {
    x: Math.min(box.x, box.x + box.width),
    y: Math.min(box.y, box.y + box.height),
    width: Math.abs(box.width),
    height: Math.abs(box.height),
  };
}

export function moveBoxTopLeftCorner(box: Box, offset: Offset, keepRatio: boolean = false): Box {
  const normalized = normalizeBox(box);
  const newBox = {
    x: normalized.x + offset.deltaX,
    y: normalized.y + offset.deltaY,
    width: normalized.width - offset.deltaX,
    height: normalized.height - offset.deltaY,
  };
  if (keepRatio) {
    const ratio = normalized.width / normalized.height;
    const newRatio = Math.abs(newBox.width / newBox.height);
    if (newRatio > ratio) {
      const newHeight = (Math.abs(newBox.width) / ratio) * Math.sign(newBox.height);
      newBox.y = newBox.y + (newBox.height - newHeight);
      newBox.height = newHeight;
    } else {
      const newWidth = Math.abs(newBox.height) * ratio * Math.sign(newBox.width);
      newBox.x = newBox.x + newBox.width - newWidth;
      newBox.width = newWidth;
    }
  }
  return newBox;
}

export function moveBoxTopRightCorner(box: Box, offset: Offset, keepRatio: boolean = false): Box {
  const normalized = normalizeBox(box);
  const newBox = {
    x: normalized.x,
    y: normalized.y + offset.deltaY,
    width: normalized.width + offset.deltaX,
    height: normalized.height - offset.deltaY,
  };
  if (keepRatio) {
    const ratio = normalized.width / normalized.height;
    const newRatio = Math.abs(newBox.width / newBox.height);
    if (newRatio > ratio) {
      const newHeight = (Math.abs(newBox.width) / ratio) * Math.sign(newBox.height);
      newBox.y = newBox.y + (newBox.height - newHeight);
      newBox.height = newHeight;
    } else {
      newBox.width = Math.abs(newBox.height) * ratio * Math.sign(newBox.width);
    }
  }
  return newBox;
}

export function moveBoxBottomRightCorner(
  box: Box,
  offset: Offset,
  keepRatio: boolean = false,
): Box {
  const normalized = normalizeBox(box);
  const newBox = {
    x: normalized.x,
    y: normalized.y,
    width: normalized.width + offset.deltaX,
    height: normalized.height + offset.deltaY,
  };
  if (keepRatio) {
    const ratio = normalized.width / normalized.height;
    const newRatio = Math.abs(newBox.width / newBox.height);
    if (newRatio > ratio) {
      newBox.height = (Math.abs(newBox.width) / ratio) * Math.sign(newBox.height);
    } else {
      newBox.width = Math.abs(newBox.height) * ratio * Math.sign(newBox.width);
    }
  }
  return newBox;
}

export function moveBoxBottomLeftCorner(box: Box, offset: Offset, keepRatio: boolean = false): Box {
  const normalized = normalizeBox(box);
  const newBox = {
    x: normalized.x + offset.deltaX,
    y: normalized.y,
    width: normalized.width - offset.deltaX,
    height: normalized.height + offset.deltaY,
  };
  if (keepRatio) {
    const ratio = normalized.width / normalized.height;
    const newRatio = Math.abs(newBox.width / newBox.height);
    if (newRatio > ratio) {
      newBox.height = (Math.abs(newBox.width) / ratio) * Math.sign(newBox.height);
    } else {
      const newWidth = Math.abs(newBox.height) * ratio * Math.sign(newBox.width);
      newBox.x = newBox.x + (newBox.width - newWidth);
      newBox.width = newWidth;
    }
  }
  return newBox;
}

export function moveBoxTopEdge(box: Box, offset: Offset): Box {
  const normalized = normalizeBox(box);
  return {
    x: normalized.x,
    y: normalized.y + offset.deltaY,
    width: normalized.width,
    height: normalized.height - offset.deltaY,
  };
}

export function moveBoxRightEdge(box: Box, offset: Offset): Box {
  const normalized = normalizeBox(box);
  return {
    x: normalized.x,
    y: normalized.y,
    width: normalized.width + offset.deltaX,
    height: normalized.height,
  };
}

export function moveBoxBottomEdge(box: Box, offset: Offset): Box {
  const normalized = normalizeBox(box);
  return {
    x: normalized.x,
    y: normalized.y,
    width: normalized.width,
    height: normalized.height + offset.deltaY,
  };
}

export function moveBoxLeftEdge(box: Box, offset: Offset): Box {
  const normalized = normalizeBox(box);
  return {
    x: normalized.x + offset.deltaX,
    y: normalized.y,
    width: normalized.width - offset.deltaX,
    height: normalized.height,
  };
}

export function isEmptyBox(box: Box): boolean {
  return box.width === 0 && box.height === 0;
}

export function isBoxTouching(box1: Box, box2: Box): boolean {
  return (
    box1.x < box2.x + box2.width &&
    box2.x < box1.x + box1.width &&
    box1.y < box2.y + box2.height &&
    box2.y < box1.y + box1.height
  );
}

export interface ViewportBox {
  viewportX: number;
  viewportY: number;
  viewportWidth: number;
  viewportHeight: number;
}

export function viewportBoxOf(pos1: ViewportPosition, pos2: ViewportPosition): ViewportBox {
  return {
    viewportX: Math.min(pos1.viewportX, pos2.viewportX),
    viewportY: Math.min(pos1.viewportY, pos2.viewportY),
    viewportWidth: Math.abs(pos2.viewportX - pos1.viewportX),
    viewportHeight: Math.abs(pos2.viewportY - pos1.viewportY),
  };
}

export function normalizeViewportBox(box: ViewportBox): ViewportBox {
  return {
    viewportX: Math.min(box.viewportX, box.viewportX + box.viewportWidth),
    viewportY: Math.min(box.viewportY, box.viewportY + box.viewportHeight),
    viewportWidth: Math.abs(box.viewportWidth),
    viewportHeight: Math.abs(box.viewportHeight),
  };
}

export function toViewportBox(box: Box, pan: Offset, zoom: number): ViewportBox {
  return {
    viewportX: (box.x - pan.deltaX) * zoom,
    viewportY: (box.y - pan.deltaY) * zoom,
    viewportWidth: box.width * zoom,
    viewportHeight: box.height * zoom,
  };
}

export function fromViewportBox(box: ViewportBox, pan: Offset, zoom: number): Box {
  return {
    x: box.viewportX / zoom + pan.deltaX,
    y: box.viewportY / zoom + pan.deltaY,
    width: box.viewportWidth / zoom,
    height: box.viewportHeight / zoom,
  };
}

export interface LocalBox {
  localX: number;
  localY: number;
  width: number;
  height: number;
}

export function fromLocalBox(box: LocalBox, origin: Position): Box {
  return {
    x: origin.x + box.localX,
    y: origin.y + box.localY,
    width: box.width,
    height: box.height,
  };
}
