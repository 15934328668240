import { IconProps } from "./props";
import { FC } from "react";

export const MSizeIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M10.7882 17.687C10.2976 17.687 10.0522 17.4198 10.0522 16.8856V6.80139C10.0522 6.50087 10.1216 6.29496 10.2602 6.18365C10.4096 6.06122 10.6282 6 10.9162 6C11.3109 6 11.6096 6.17809 11.8122 6.53426L14.9962 11.7934L18.1482 6.55096C18.2656 6.32835 18.4042 6.18365 18.5642 6.11687C18.7349 6.03896 18.9429 6 19.1882 6C19.7002 6 19.9562 6.26713 19.9562 6.80139V16.8856C19.9562 17.4198 19.7056 17.687 19.2042 17.687C18.7029 17.687 18.4522 17.4198 18.4522 16.8856V8.65461L15.7162 13.2626C15.5349 13.6188 15.2949 13.7969 14.9962 13.7969C14.7082 13.7969 14.4629 13.6188 14.2602 13.2626L11.5242 8.65461V16.8856C11.5242 17.4198 11.2789 17.687 10.7882 17.687Z"
        fill="#ffffff"
      />
    </svg>
  );
};
