import { IconProps } from "./props";
import { FC } from "react";

export const PixelateToolIcon: FC<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <rect x="8" y="4" width="4" height="4" fill="#A3A3A3" />
      <rect x="4" y="8" width="4" height="4" fill="#A3A3A3" />
      <rect x="8" y="12" width="4" height="4" fill="#A3A3A3" />
      <rect x="4" y="16" width="4" height="4" fill="#A3A3A3" />
      <rect x="12" y="16" width="4" height="4" fill="#A3A3A3" />
      <rect x="12" y="8" width="4" height="4" fill="#A3A3A3" />
      <rect x="16" y="4" width="4" height="4" fill="#A3A3A3" />
      <rect x="16" y="12" width="4" height="4" fill="#A3A3A3" />
    </svg>
  );
};
