import { Offset } from "./offset";

export interface Position {
  x: number;
  y: number;
}

export function offsetPosition(pos: Position, offset: Offset): Position {
  return { x: pos.x + offset.deltaX, y: pos.y + offset.deltaY };
}

export interface ViewportPosition {
  viewportX: number;
  viewportY: number;
}

export function toViewportPosition(pos: Position, pan: Offset, zoom: number): ViewportPosition {
  return {
    viewportX: (pos.x - pan.deltaX) * zoom,
    viewportY: (pos.y - pan.deltaY) * zoom,
  };
}

export function fromViewportPosition(pos: ViewportPosition, pan: Offset, zoom: number): Position {
  return {
    x: pos.viewportX / zoom + pan.deltaX,
    y: pos.viewportY / zoom + pan.deltaY,
  };
}

export interface LocalPosition {
  localX: number;
  localY: number;
}
